import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useReducer,
} from "react";
import { AgGridReact } from "ag-grid-react";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-balham.css";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./Table.scss";
import { theme } from "../../theme";
import {
  getColumnDefs,
  currencyFormatter,
  percentageFormatter,
  NoFormatter,
  defaultCellStyle,
  cellValueToNumber,
} from "./utils";
import { LicenseManager } from "ag-grid-enterprise";
import { fetchData, getMultipleModelsData } from "../../pages/Home/action";
import { CustomRendererComponent } from "../../pages/Home/CustomComponents/CustomRendererComponent";
import { useDispatch, useSelector } from "react-redux";
import { EditWeekData } from "../../pages/Home/CustomComponents/editWeekData";
import AddHideMetrics from "../../pages/Home/CustomComponents/ShowHideMetrics";
import { EditTextField } from "../../pages/Home/CustomComponents/editTextFields";
import CellTextField from "../../pages/Home/CustomComponents/CellTextField";
import CellImageField from "../../pages/Home/CustomComponents/CellImageField";
import CellPopOverImageField from "../../pages/Home/CustomComponents/CellPopOverImageField";
import DropdownCellRenderer from "../../pages/Home/CustomComponents/dropdownCellRender";
import CellSwitchField from "../../pages/Home/CustomComponents/CellSwitchField";
import CellInfoIcon from "../../pages/Home/CustomComponents/CellInfoIcon";
import { PreformattedText } from "../../pages/Home/CustomComponents/PreformattedText";
import CellAddIcon from "../../pages/Home/CustomComponents/CellAddIcon";
import CellMultiSelectDropdown from "../../pages/Home/CustomComponents/CellMultiSelectDropdown";
import CellChips from "../../pages/Home/CustomComponents/CellChips";
import CircularColorChips from "../../pages/Home/CustomComponents/CircularColorChips";
import CellColor from "../../pages/Home/CustomComponents/CellColor";
import ColumnColorRange from "../../pages/Home/CustomComponents/ColumnColorRange";
import CellPopOverAction from "../../pages/Home/CustomComponents/CellPopOverAction";
import _, { constant, invoke } from "lodash";
import CellMultipleSelect from "../../pages/Home/CustomComponents/CellMultipleSelect";
import CellDropdownMap from "../../pages/Home/CustomComponents/CellDropdownMap";
import CellDropdownMulti from "../../pages/Home/CustomComponents/CellDropdownMulti";
import CustomMultiComponentCell from "../../pages/Home/CustomComponents/CustomMultiComponentCell";
import useAction from "../../utils/useAction";
import tableActionsToolPanel from "./tableActionsToolPanel";
import CellGroupSet from "../../pages/Home/CustomComponents/CellGroupSet";
import CellRendererSelector from "../../pages/Home/CustomComponents/CellRendererSelector";
import CellCompute from "../../pages/Home/CustomComponents/CellCompute";
import GroupCellRenderer from "../../pages/Home/CustomComponents/GroupCellRenderer";
import TooltipComponent from "../../pages/Home/CustomComponents/TooltipComponent";
import CellToggle from "../../pages/Home/CustomComponents/CellToggle";
import CellDateRange from "../../pages/Home/CustomComponents/CellDateRange";
import NestedTable from "../../pages/Home/CustomComponents/NestedTable";
import useCustomMenu, { freezeState } from "./ColumnMenu";
import CellCheckboxField from "../../pages/Home/CustomComponents/CellCheckboxField";
import CellInfoTag from "../../pages/Home/CustomComponents/CellInfoTag";
import { getComparableValue } from "./filtering";
import CustomCellValueRenderer from "../../pages/Home/CustomComponents/CustomCellValueRenderer";
import GroupValueSwitch from "../../pages/Home/CustomComponents/GroupValueSwitch";
import CellCheck from "../../pages/Home/CustomComponents/CellCheck";
import { CustomModal } from "../../pages/Home/CustomComponents/customModal";
const LICENSE_KEY =
  "CompanyName=Impact Analytics,LicensedGroup=31Jan22 Purchase,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-025014,ExpiryDate=31_January_2023_[v2]_MTY3NTEyMzIwMDAwMA==e4f58ef1fe10261cf66aa1e5a5cb2da6";
LicenseManager.setLicenseKey(LICENSE_KEY);

const getInitialMenuState = (columnDefs) => {
  let data = {};
  columnDefs.forEach((col) => {
    if (col.children) {
      data = {
        ...data,
        ...getInitialMenuState(col.children),
      };
    } else {
      data[col.field] = {
        freezeColumn: {
          state: col["pinned"] ? freezeState.FREEZE : freezeState.UNFREEZE,
        },
        columnWidth: {
          checked: "autoAdjust",
        },
      };
    }
  });
  return data;
};

const Table = (props) => {
  const {
    headerHeight,
    rowHeight,
    createExtraColDefs,
    filterColumns,
    filterColumnId,
    autoHeight,
    filterColumn,
  } = props;
  const dispatch = useDispatch();
  const {
    deleteTableRow,
    isExportTable,
    selectedRowInfo,
    tableInfo,
    columnsInfo,
    dependentInfo,
    tableValues,
    dropdownSelectionData,
    editActionInfo,
    tableInfoSaved,
    formEditedValues,
    filtersDependentInfo,
    tableDefs,
    clientConfig,
    gridRefs,
    tableModals,
  } = useSelector((state) => state.home);
  const { formMasterValuesData, tableConfig, tableFilters } = useSelector(
    (state) => state.filter,
  );
  const tableData = useMemo(() => {
    const key = props.dynamicPayload ? `${props.apikey}_dynamic` : props.apikey;
    return tableInfo[key] || null;
  }, [props, tableInfo]);

  const storedColumnDefs = tableDefs[props.apikey];
  const invokeAction = useAction();
  const gridRef = useRef();
  const gridStore = useRef(gridRefs);
  const containerRef = useRef();
  const { open = false, modalInfo = {} } = tableModals[props.apikey] || {};
  const [defaultData, setDefaultData] = useState(null);
  const [columnDefs, setColumnDefs] = useState([props.columnDefs]);
  const getMainMenuItems = useCustomMenu(getInitialMenuState(props.columnDefs));
  const getMenuTabs = useCallback((props) => {
    const defaultMenuTabs = [
      "generalMenuTab",
      "filterMenuTab",
      "columnsMenuTab",
    ];
    if (props?.menuTabs) {
      return props.menuTabs;
    }
    return defaultMenuTabs;
  }, []);

  const [defaultColDef, setDefaultColDef] = useState(() => ({
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: "agTextColumnFilter",
    editable: props.editable,
    resizable: true,
    minWidth: 100,
    autoHeight: props.autoHeight,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: (params) => {
      return defaultCellStyle(params, props);
    },
    menuTabs: getMenuTabs(props),
  }));
  const [refresh, setRefresh] = useState(false);
  const [headerMap, setHeaderMap] = useState({});
  const [fHeaderMap, setFHeaderMap] = useState(null);
  const [columnsToFilter, setColumnsToFilter] = useState([]);
  const [firstRefresh, setFirstRefresh] = useState(true);
  const [gridOptions, setGridOptions] = useState({
    rememberGroupStateWhenNewData: true,
    suppressScrollOnNewData: true,
    domLayout: (props?.sizeToRows && "autoHeight") || "normal",
    onColumnRowGroupChanged: (params) => {
      columnRowGroupChanged(params, props, invokeAction);
    },
    onCellEditingStarted: (params) => {
      if (params.node.rowPinned === "top") {
        params.api.stopEditing();
      }
    },
  });
  const [context, setContext] = useState({
    editedColumns: [],
  });
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState({});
  const [pinnedTopRowData, setPinnedTopRowData] = useState(null);
  const [heightExpanded, setHeightExpanded] = useState(false);
  const [gridHeight, setGridHeight] = useState(props.height || "300px");
  const tableFilterInfo = useMemo(() => {
    return tableFilters[props.apikey] || null;
  }, [tableFilters, props]);

  const RefreshTable = () => setRefresh((prev) => !prev);
  useEffect(() => {
    const { apikey, isApiNotRequird } = props;
    if (apikey && !isApiNotRequird) {
      let reqParams = [apikey];
      let payload = {};
      if (tableData && props?.callNotRequiredIfDataExist) {
        setRowData(tableData);
        if (props.isStoreRequired) {
          if (dependentInfo[`${props.dependentOnTab}`]) {
            let params = {};
            if (props.replaceOtherApi && tableInfo[props.replaceOtherApi]) {
              params[`${props.source}`] = [
                ...tableInfo[`${props.replaceOtherApi}`],
              ];
              dispatch({
                type: "TABLE_DATA",
                payload: params,
              });
            }
          }
        }
      } else if (props?.isSaveRequired && tableInfoSaved[apikey]) {
        setRowData([...tableInfoSaved[apikey]]);
        dispatch({
          type: "TABLE_DATA",
          payload: { [apikey]: _.cloneDeep(tableInfoSaved[apikey]) },
        });
      } else {
        // else if (!Object.keys(tableValues).includes(apikey)) {
        getMultipleModelsData({ model_names: reqParams }).then((res) => {
          setDefaultData([...res[apikey]]);
          setRowData([...res[apikey]]);
          payload[apikey] = [...res[apikey]];
          // payload[`${apikey}_copy`] = [...res[apikey]];
          // dispatch({
          // 	type: "TABLE_VALUES",
          // 	payload: payload,
          // });
          if (props.isStoreRequired) {
            let params = {};
            params[`${apikey}`] = [...res[apikey]];
            if (dependentInfo[`${props.dependentOnTab}`]) {
              if (
                props.replaceOtherApi &&
                tableInfo[`${props.replaceOtherApi}`]
              ) {
                params[`${props.source}`] = [
                  ...tableInfo[`${props.replaceOtherApi}`],
                ];
              }
            }
            if (
              dependentInfo[`${props.default_table_data}`] &&
              props.default_table_data
            ) {
              params[`${props.source}`] = [
                ...tableInfo[`${props.default_table_data}`],
              ];
            }

            if (props.isCopyRequired) {
              params[`${apikey}_copy`] = [...res[apikey]];
            }

            if (props.storeDataBasedoOnKey) {
              if (Object.keys(props.storeDataBasedoOnKey).length > 0) {
                const basedOnKeyData = res[apikey].filter((obj) => {
                  if (
                    obj[`${props.storeDataBasedoOnKey.key}`] ===
                    props.storeDataBasedoOnKey.value
                  ) {
                    return obj;
                  }
                });
                params[`${apikey}`] = basedOnKeyData;
              }
            }
            if (props?.dynamicTable) {
              params[`${apikey}_dynamic`] = [];
            }
            dispatch({
              type: "TABLE_DATA",
              payload: params,
            });
          }
        });

        if (props.otherApiKeys) {
          setTimeout(() => {
            getMultipleModelsData({ model_names: props.otherApiKeys }).then(
              (res) => {
                if (props.isStoreRequired) {
                  let params = {};
                  if (props.otherApiKeys) {
                    props.otherApiKeys.forEach((apiObj) => {
                      params[`${apiObj}`] = [...res[apiObj]];
                    });
                  }
                  dispatch({
                    type: "TABLE_DATA",
                    payload: params,
                  });
                }
              },
            );
          }, 1000);
        }
        if (props.isColumnStoreRequired) {
          let columnParams = {};
          columnParams[`${apikey}`] = props.columnDefs;
          if (props.isCopyRequired) {
            columnParams[`${apikey}_copy`] = props.columnDefs;
          }
          if (props.addColumnsList) {
            columnParams[`${apikey}_add`] = props.addColumnsList;
          }
          dispatch({
            type: "COLUMN_DATA",
            payload: columnParams,
          });
        }

        if (props.showHideMetrics) {
          setGridOptions({
            ...gridOptions,
            sideBar: sideBar,
          });
        }
      }
      // else {
      // 	console.log("-------------")
      // 	setRowData(tableValues[apikey]);
      // }
    }
  }, [props.apikey]);
  useEffect(() => {
    if (props.isNestedTable) {
      if (rowData.length > 0) {
        props.setNestedTableData(rowData);
      }
    }
  }, [rowData]);

  //on unmount
  useEffect(() => {
    return () => {
      invokeAction(props?.actionOnUnmount);
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: { [props?.actionKey || props?.apikey]: null },
      });
    };
  }, []);

  const isRedrawRows = (gridRef, props, formEditedValues) => {
    return (
      gridRef &&
      gridRef.current &&
      gridRef.current.api &&
      (!props.isRedrawRowsNotRequired || formEditedValues[`isRefreshCells`])
    );
  };
  useEffect(() => {
    if (tableData) {
      setRowData(tableData);
      if (props.childGroupDefaultOpenOptions) {
        setTimeout(() => {
          expandChildGroup();
        }, 5);
      }
      // setTimeout(() => gridRef?.current?.api.onFilterChanged(), 0);
      if (props.showHideMetrics) {
        setGridOptions({
          ...gridOptions,
          sideBar: sideBar,
        });
      }
      if (formEditedValues[`isRefreshCells`]) {
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: {
            isRefreshCells: false,
          },
        });
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        try {
          gridApi.setRowData([...rowData]);
        } catch (e) {
          console.log("setting row Data", e);
        }
      }, 0);
    }
  }, [rowData, gridApi]);

  const filterTable = useCallback(
    (filter_data, sourceData, tableToFilter) => {
      const idSet = new Set();
      sourceData.forEach((row) => {
        if (
          Object.keys(filter_data).every(
            (key) =>
              filter_data[key].size == 0 ||
              filter_data[key].has(row[fHeaderMap[key]]),
          )
        ) {
          idSet.add(row.id);
        }
      });
      return tableToFilter.filter((row) => idSet.has(row.id));
    },
    [fHeaderMap],
  );

  useEffect(() => {
    if (props?.filtering) {
      const filterData = filtersDependentInfo[props.filtering.filterId];
      if (filterData && defaultData && defaultData.length > 0) {
        const filteredRows = filterTable(
          filterData,
          defaultData,
          tableInfo[props.apikey],
        );
        dispatch({
          type: "TABLE_DATA",
          payload: { [props.apikey]: filteredRows },
        });
      }
    }
  }, [filtersDependentInfo]);

  useEffect(() => {
    if (columnsInfo[`${props.apikey}`]) {
      setColumnDefs([
        ...getColumnDefs(
          columnsInfo[`${props.apikey}`],
          props.isValueFormatterRequired,
          props.importStyle,
          dispatch,
        ),
      ]);
      if (gridRef && gridRef.current && gridRef.current.api) {
        gridRef.current.api.setColumnDefs(columnDefs);
        gridApi.sizeColumnsToFit();
      }
    }
  }, [columnsInfo]);

  useEffect(() => {
    if (props.columnDefs.length > 0) {
      const defs = getColumnDefs(
        props.columnDefs,
        props.isValueFormatterRequired,
        props.importStyle,
        dispatch,
        storedColumnDefs,
        clientConfig,
      );
      setColumnDefs(defs);
    }
    setColumnDefs(props.columnDefs);
  }, [props.columnDefs]);

  useEffect(() => {
    if (props.isOtherRequest) {
      let payload = {};
      fetchData(props.isOtherRequest).then((res) => {
        setRowData(res);
        payload[props.isOtherRequest] = [...res];
        dispatch({
          type: "TABLE_DATA",
          payload: payload,
        });
      });
    }
  }, [props.isOtherRequest]);

  const headerHeightGetter = () => {
    var columnHeaderTexts = [
      ...document.querySelectorAll(".ag-header-cell-text"),
    ];
    var clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight,
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  };

  const groupHeaderHeightGetter = () => {
    //ag-header-group-cell-label
    var columnHeaderTexts = [
      ...document.querySelectorAll(".ag-header-group-cell-label"),
    ];

    var clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight,
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  };

  const onFirstDataRendered = (params) => {
    setGridApi(params.api);
    gridColumnApi.setColumnsVisible(
      columnsToFilter,
      props.filterDefaultAllVisible,
    );
    //to load the default table style
    if (
      props.tableCssStyle === "default" ||
      props.tableCssStyle === undefined
    ) {
      var allColumnIds = [];
      gridColumnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      gridColumnApi.autoSizeColumns(allColumnIds, "skipHeader");
    }

    if (props.tableCssStyle === "sizeColumnsToFit") {
      if (Object.keys(gridApi).length > 0) {
        params.api.sizeColumnsToFit();
      }
    }
    if (props.tableCssStyle === "autoSizeAllColumns") {
      if (Object.keys(gridColumnApi).length > 0) {
        gridColumnApi.autoSizeAllColumns();
      }
    }
    if (props.isDefaultCheckBoxSelection) {
      params.api.forEachNode((node) =>
        node.setSelected(
          !!node.data &&
            props.isDefaultCheckBoxSelection.indexOf(
              node.data[`${props.defaultCheckBasedOn}`],
            ) !== -1,
        ),
      );
    }
    var padding = 10;
    var height = headerHeightGetter() + (props?.headerPadding || padding);
    var groupHeaderHeight = groupHeaderHeightGetter() - 10;

    params.api.setHeaderHeight(height);
    params.api.setGroupHeaderHeight(groupHeaderHeight);
    params.api.resetRowHeights();
    params.api.onFilterChanged();
    if (props?.isSaveRequired) {
      params.api.setRowData(tableData);
    }
    if (props?.actionOnFirstRender) {
      invokeAction(props.actionOnFirstRender);
    }
    if (props?.filtering) {
      const filterData = filtersDependentInfo[props.filtering.filterId];
      if (filterData && defaultData && defaultData.length > 0) {
        const filteredRows = filterTable(filterData, defaultData, tableData);
        dispatch({
          type: "TABLE_DATA",
          payload: { [props.apikey]: filteredRows },
        });
      }
    }
    updatePinnedRowData(params);
    RefreshTable();
  };
  const onRowDataChanged = (params) => {
    if (props?.actionOnInit) {
      invokeAction(props.actionOnInit);
    }
  };
  // Export grid to excel
  useEffect(() => {
    // if (isExportTable) {
    // 	gridRef.current.api.exportDataAsExcel({
    // 		onlySelected: selectedRowInfo[props?.apikey].length > 0,
    // 	});
    // 	dispatch({
    // 		type: "EXPORT_TO_EXCEL",
    // 		payload: false,
    // 	});
    // }
    if (isExportTable) {
      if (
        Object.keys(selectedRowInfo).length &&
        selectedRowInfo[props?.apikey].length > 0
      ) {
        gridRef.current.api.exportDataAsExcel({
          onlySelected: selectedRowInfo[props?.apikey].length > 0,
        });
      } else {
        gridRef.current.api.exportDataAsExcel();
      }
      dispatch({
        type: "EXPORT_TO_EXCEL",
        payload: false,
      });
    }
  }, [isExportTable]);

  // Delete grid rows
  // useEffect(() => {
  //   let hiddenColumnsArray = filterColumns;
  //   if (
  //     dropdownSelectionData !== undefined &&
  //     dropdownSelectionData[filterColumnId] !== undefined &&
  //     gridColumnApi.setColumnsVisible !== undefined
  //   ) {
  //     //column filter
  //     let columnsArray = [];
  //     dropdownSelectionData[filterColumnId]?.map((value) => {
  //       columnsArray.push(value.value);
  //     });
  //     gridColumnApi.setColumnsVisible(columnsArray, true);
  //     let array3 = hiddenColumnsArray?.filter(
  //       (val) => !columnsArray.includes(val)
  //     );
  //     gridColumnApi.setColumnsVisible(array3, false);
  //     //row filter
  //     if (props.rowFilter) {
  //       const rowArray = dropdownSelectionData[filterColumnId]?.map(
  //         ({ value }) => value
  //       );
  //       console.log(rowArray)
  //       setRowData((prev) => {
  //         console.log(tableInfo[props.apikey])

  //         const filteredRows = tableInfo[props.apikey]?.filter((row) => rowArray.includes(row[filterColumn]));
  //           console.log(filteredRows)
  //           return [...filteredRows]
  //       });
  //     }
  //   }
  // }, [dropdownSelectionData]);

  const setupColumnFilters = (columnDefs) => {
    const _fHeaderMap = {};
    function extractFields(columns, arr) {
      columns.forEach((col) => {
        if (col?.children) extractFields(col.children, arr);
        else {
          arr.push(col.field);
          _fHeaderMap[col?.fHeader || col.headerName] = col.field;
        }
      });
    }
    const _headerMap = {};
    columnDefs.forEach((col) => {
      const arr = [];
      if (col?.children) extractFields(col.children, arr);
      else {
        arr.push(col.field);
        _fHeaderMap[col?.fHeader || col.headerName] = col.field;
      }
      _headerMap[col?.identifier || col.headerName] = arr;
    });
    setHeaderMap(_headerMap);
    setFHeaderMap(_fHeaderMap);
    setColumnsToFilter((prev) => {
      const obj = [];
      filterColumns?.forEach((header) =>
        _headerMap[header]?.forEach((field) => obj.push(field)),
      );
      return obj;
    });
  };

  // Export grid to excel
  // useEffect(() => {
  // 	if (isExportTable) {
  // 		gridRef.current.api.exportDataAsExcel({
  // 			onlySelected: selectedRowInfo[props?.apikey].length > 0,
  // 		});
  // 		dispatch({
  // 			type: "EXPORT_TO_EXCEL",
  // 			payload: false,
  // 		});
  // 	}
  // }, [isExportTable]);

  // Delete grid rows
  useEffect(() => {
    if (deleteTableRow) {
      const selectedRowData = gridRef.current.api.getSelectedRows();
      gridRef.current.api.applyTransaction({ remove: selectedRowData });
      dispatch({
        type: "DELETE_SELECTED_TABLE_ROW",
        payload: false,
      });
    }
  }, [deleteTableRow]);
  useEffect(() => {
    let hiddenColumnsArray = columnsToFilter;
    if (
      dropdownSelectionData !== undefined &&
      dropdownSelectionData[filterColumnId] !== undefined &&
      gridColumnApi.setColumnsVisible !== undefined
    ) {
      //column filter
      let columnsArray = [];
      dropdownSelectionData[filterColumnId]?.forEach(({ value }) => {
        headerMap[value]?.forEach((field) => columnsArray.push(field));
      });
      gridColumnApi.setColumnsVisible(columnsArray, true);
      let array3 = hiddenColumnsArray?.filter(
        (val) => !columnsArray.includes(val),
      );
      gridColumnApi.setColumnsVisible(array3, false);
      //row filter
    }
    if (props?.rowFilter) {
      if (dropdownSelectionData && dropdownSelectionData[filterColumnId]) {
        const rowArray = dropdownSelectionData[filterColumnId]?.map(
          ({ value }) => value,
        );
        if (props?.filterByHide) {
          if (tableData) {
            const filteredTable = tableData.map((row) => ({
              ...row,
              "#filter_hidden":
                rowArray.length > 0
                  ? rowArray.includes(row[filterColumn])
                    ? "N"
                    : "Y"
                  : "N",
            }));
            dispatch({
              type: "TABLE_DATA",
              payload: { [props.apikey]: filteredTable },
            });
          }
        } else {
          setRowData((prev) => {
            const filteredRows =
              tableData?.filter((row) =>
                rowArray.includes(row[filterColumn]),
              ) || [];
            return [...filteredRows];
          });
        }
      }
    }
  }, [dropdownSelectionData, refresh]);

  const isImagePresent = () => {
    const imageColumn = props.columnDefs.find((column) => {
      return column?.cellRenderer === "childMessageRenderer";
    });

    if (imageColumn) {
      return true;
    }

    return false;
  };

  const getRowStyle = (params) => {
    if (params.data && params.data.is_finalized) {
      return { background: theme.palette.bgTableFinalized };
    } else if (params.data && params.data.is_product_performance) {
      return {
        background:
          params.data.status === "track"
            ? theme.palette.bgSuccessTransparent
            : params.data.status === "over"
              ? theme.palette.bgWarningTransparent
              : theme.palette.bgDangerTransparent,
      };
    } else if (props.rowColor) {
      if (params.node.rowIndex === props.rowColor.index) {
        return { background: props.rowColor.color };
      }
    }

    return {
      background: props.bgtbcolor ? props.bgtbcolor : theme.palette.textWhite,
    };
  };

  const onGridReady = async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setupColumnFilters(params.columnApi.columnModel.columnDefs);
    dispatch({
      type: "SET_GRID_REF",
      payload: { [props.actionKey]: gridRef },
    });
  };

  const cellValueChanged = async (params) => {
    if (params.newValue !== params.oldValue) {
      const updatedData = [];
      updatedData.push({
        rowIndex: params.rowIndex,
        colId: params.column.colId,
      });
      setContext({
        // ...context,
        editedColumns: [...context.editedColumns].concat(updatedData),
      });

      if (props.isMultiplyApplyToRow) {
        let mult = 0;
        gridRef.current.api.forEachNode((node) => {
          if (params.rowIndex === node.rowIndex) {
          } else if (node.rowIndex === props.isMultiplyApplyToRow) {
            let updatedValue = Math.round(
              params.newValue * mult.replace(/\,/g, ""),
            );
            let paramsReq = {};
            node.setDataValue(
              params.colDef.field,
              NoFormatter({ value: updatedValue }),
            );

            if (props.updateToOtherTables) {
              // props.updateToOtherTables.forEach(tableObj=>{
              let tableObj = props.updateToOtherTables[0];
              const tableData = tableInfo[`${tableObj}`];
              // let colName = params.colDef.field.split("week_")
              //     // console.log("*******",node.data[`${params.colDef.field}`])
              //     // dataObj[`W ${colName[1]}`] = node.data[`${params.colDef.field}`]
              //     // dataObj.data[`${colName[1] -1 }`] = parseInt(Math.round(node.data[`${params.colDef.field}`].replace(/\,/g,'')))
              // tableData[3][`W ${colName[1]}`] = node.data[`${params.colDef.field}`]
              // tableData[3]['data'][`${colName[1] -1 }`] = parseInt(Math.round(node.data[`${params.colDef.field}`].replace(/\,/g,'')))
              tableData.filter((dataObj, index) => {
                if (dataObj["id"] == 4) {
                  let colName = params.colDef.field.split("week_");
                  dataObj[`W ${colName[1]}`] =
                    node.data[`${params.colDef.field}`];
                  dataObj.data[`${colName[1] - 1}`] = parseInt(
                    Math.round(
                      node.data[`${params.colDef.field}`].replace(/\,/g, ""),
                    ),
                  );
                }
              });
              paramsReq[`${tableObj}`] = tableData;
              // })

              dispatch({
                type: "TABLE_DATA",
                payload: paramsReq,
              });
            }
          } else {
            mult = node.data[`${params.colDef.field}`];
          }
        });
      }
      if (props.isDifferenceApplyToRow) {
        let mult = 0;
        gridRef.current.api.forEachNode((node) => {
          if (params.rowIndex === node.rowIndex) {
          } else if (node.rowIndex === props.isDifferenceApplyToRow) {
            let updatedValue = Math.round(
              mult - params.newValue.replace(/\,/g, ""),
            );
            let paramsReq = {};
            node.setDataValue(
              params.colDef.field,
              NoFormatter({ value: updatedValue }),
            );

            if (props.updateToOtherTables) {
              let tableObj = props.updateToOtherTables[0];
              const tableData = tableInfo[`${tableObj}`];
              tableData.filter((dataObj, index) => {
                if (dataObj["id"] == 6) {
                  let colName = params.colDef.field.split("week_");
                  dataObj[`W ${colName[1]}`] =
                    node.data[`${params.colDef.field}`];
                  dataObj.data[`${colName[1] - 1}`] = parseInt(
                    Math.round(
                      node.data[`${params.colDef.field}`].replace(/\,/g, ""),
                    ),
                  );
                }
              });
              paramsReq[`${tableObj}`] = tableData;

              dispatch({
                type: "TABLE_DATA",
                payload: paramsReq,
              });
            }
          } else {
            mult = node.data[`${params.colDef.field}`];
          }
        });
      }
      if (
        props.updateToOtherTable &&
        Object.keys(props.updateToOtherTable).length > 0
      ) {
        if (
          params.data &&
          params.data[`${props.updateToOtherTable.rowKey}`] ===
            props.updateToOtherTable.rowValue
        ) {
          const updateData = tableInfo[
            `${props.updateToOtherTable.parent_table_key}`
          ].filter((obj) => {
            if (
              obj[`${props.updateToOtherTable.updatedRowKey}`] ===
              props.updateToOtherTable.updatedRowValue
            ) {
              obj[`${params.colDef.field}`] = params.newValue.replace(
                /\,/g,
                "",
              );
            }
            return obj;
          });
          let paramsReq = {};
          paramsReq[`${props.updateToOtherTable.parent_table_key}`] = [
            ...updateData,
          ];
          dispatch({
            type: "TABLE_DATA",
            payload: paramsReq,
          });
          if (props.updateToOtherTable.apiKeys) {
            dispatch({
              type: "FORM_EDITED_VALUES",
              payload: {
                isGraphDataUupdated: true,
                graphApiKeys: props.updateToOtherTable.apiKeys,
              },
            });
          }
        }
      }
      if (props["onCellEditedColumnKey"]) {
        let payloadReq = {};
        payloadReq[`${props["onCellEditedColumnKey"]}`] = params.newValue;
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: payloadReq,
        });
      }
      if (params.colDef.valueLimit) {
        const newValue = Number(params.newValue);
        let {
          min = -9999,
          max = 9999,
          message,
          modal,
          constraintLevel = 1,
        } = params.colDef.valueLimit;
        min = typeof min === "string" ? Number(params.data[min]) : min;
        max = typeof max === "string" ? Number(params.data[max]) : max;
        if (newValue < min || newValue > max) {
          //limit breached
          //decide what action to take
          dispatch({
            type: "SET_PARAMS",
            payload: { [props.apikey]: params },
          });
          if (modal) {
            dispatch({
              type: "SET_TABLE_MODAL",
              payload: { [props.apikey]: { open: true, modalInfo: modal } },
            });
          } else if (constraintLevel == 1) {
            let oldValue = Number(params.oldValue);
            oldValue = Math.min(Math.max(oldValue, min), max);
            params.node.setDataValue(
              params.column.getColId(),
              oldValue.toString(),
            );
          }
          if (message) {
            dispatch({
              type: "TABLE_ALERT",
              payload: {
                [props.key]: { value: true, message, variant: "warning" },
              },
            });
          }
          return;
        } else {
        }
      }
      actionOnCellValueChange(params);
      updatePinnedRowData(params);
      gridRef.current.api.refreshCells();
    }
  };

  const onCellClicked = async (params) => {
    if (props.clickChangeValue) {
      params.data[props.clickChangeField] = props.clickChangeValue;
    }
    gridRef.current.api.refreshCells();
  };

  const onSelectionChanged = (event) => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedOptions = gridApi.getSelectedRows();
    if (props.isStoreRequired || props.isRowSelectionRequired) {
      let params = {};
      if (props.isOtherRequest) {
        params[`${props.isOtherRequest}`] = [...selectedOptions];
      } else {
        params[`${props.apikey}`] = [...selectedOptions];
      }
      dispatch({
        type: "SELECTED_ROWS",
        payload: params,
      });
    }
    if (props.onRowselectionChanged) {
      props.onRowselectionChanged(selectedOptions, gridApi);
    }
    let params = {};
    if (props.selectedRowsKey) {
      params[`${props.selectedRowsKey}`] = selectedOptions;
    }
    dispatch({
      type: "CHECKED_ROWS",
      payload: params,
    });
    params[`oddSelectionChange`] = !dependentInfo[`oddSelectionChange`];
    params[`evenSelectionChange`] = !dependentInfo[`evenSelectionChange`];
    params[`${props.apikey}_singleGroupSelection`] =
      selectedNodes.length > 0 && isSingleGroupSelected(selectedNodes);
    dispatch({
      type: "DEPENDENT_COMPONENTS",
      payload: params,
    });
  };
  const sideBar = useMemo(() => {
    let toolPanel = [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
      {
        id: "show_hide_metrics",
        labelDefault: props.subRowHideOnAllRows
          ? "Show/Hide Rows"
          : "Show/Hide Metrics",
        labelKey: "show_hide_metrics",
        iconKey: "menu",
        toolPanel: AddHideMetrics,
        toolPanelParams: {
          onChange: true,
          tableRef: gridRef,
          groupBy: props.groupBy,
          subGroupBy: props.subGroupBy,
          subRowHideOnAllRows: props.subRowHideOnAllRows,
          showHideOnOtherGroups: props.showHideOnOtherGroups,
          sub_metric_level: props.sub_metric_level,
          subMetricTitle: props.subMetricTitle,
          metricTitle: props.metricTitle,
          isActiveGroupkey: props.isActiveGroupkey,
          panelStyle: props?.panelStyle,
          sidebarStyle: props?.sidebarStyle,
        },
      },
    ];
    if (props?.isToolPanelFiltersNotRequired) {
      toolPanel = [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "show_hide_metrics",
          labelDefault: props.subRowHideOnAllRows
            ? "Show/Hide Rows"
            : "Show/Hide Metrics",
          labelKey: "show_hide_metrics",
          iconKey: "menu",
          toolPanel: AddHideMetrics,
          toolPanelParams: {
            onChange: true,
            tableRef: gridRef,
            groupBy: props.groupBy,
            subGroupBy: props.subGroupBy,
            subRowHideOnAllRows: props.subRowHideOnAllRows,
            showHideOnOtherGroups: props.showHideOnOtherGroups,
            sub_metric_level: props.sub_metric_level,
            subMetricTitle: props.subMetricTitle,
            metricTitle: props.metricTitle,
            isActiveGroupkey: props.isActiveGroupkey,
            panelStyle: props?.panelStyle,
            sidebarStyle: props?.sidebarStyle,
          },
        },
      ];
    }
    let params = {
      toolPanels: toolPanel,
      //   defaultToolPanel: 'filters',
    };
    if (props.isToolPanelRequired) {
      params = {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Show/Hide metrics",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      };
    }
    if (props?.isShowHideMetricsNotRequired) {
      params = {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      };
    }
    if (props?.isTableActionsRequired) {
      params = {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "table_actions",
            labelDefault: "Table Actions",
            labelKey: "table_actions",
            toolPanel: tableActionsToolPanel,
          },
        ],
      };
    }
    return params;
  }, []);

  const sidePanel = useMemo(() => {
    let toolPanel = [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
      {
        id: "show_hide_metrics",
        labelDefault: props.subRowHideOnAllRows
          ? "Show/Hide Rows"
          : "Show/Hide Metrics",
        labelKey: "show_hide_metrics",
        iconKey: "menu",
        toolPanel: AddHideMetrics,
        toolPanelParams: {
          onChange: true,
          tableRef: gridRef,
          groupBy: props.groupBy,
          subGroupBy: props.subGroupBy,
          subRowHideOnAllRows: props.subRowHideOnAllRows,
          showHideOnOtherGroups: props.showHideOnOtherGroups,
          sub_metric_level: props.sub_metric_level,
          subMetricTitle: props.subMetricTitle,
          metricTitle: props.metricTitle,
          isActiveGroupkey: props.isActiveGroupkey,
          panelStyle: props?.panelStyle,
          sidebarStyle: props?.sidebarStyle,
          hideSubRows: props?.hideSubRows,
        },
      },
      {
        id: "table_actions",
        labelDefault: "Table Actions",
        labelKey: "table_actions",
        toolPanel: tableActionsToolPanel,
      },
    ];
    const hiddenPanels = new Set(props?.hiddenPanels);
    const params = {
      toolPanels: toolPanel.filter((panel) => !hiddenPanels.has(panel.id)),
    };
    return params;
  }, []);

  function isExternalFilterPresent() {
    return true;
  }

  function doesExternalFilterPass(node) {
    if (node.data.hasOwnProperty("#hidden")) {
      if (node.data["#hidden"] === "Y") {
        return false;
      } else {
        if (node.data.hasOwnProperty("#filter_hidden")) {
          return node.data["#filter_hidden"] !== "Y";
        }
        return true;
      }
    }

    if (node.data.hasOwnProperty("#filter_hidden")) {
      return node.data["#filter_hidden"] !== "Y";
    }
    if (tableFilterInfo) {
      return Object.keys(tableFilterInfo).every((criteria) => {
        return tableFilterInfo[criteria].every((obj) => {
          return (
            getComparableValue(node.data[obj.column], criteria) === obj.value
          );
        });
      });
    }
    return !node.data.hide;
  }

  const expandParentGroup = useCallback(() => {
    gridRef.current.api.forEachNode((node) => {
      if (node.level === 0) {
        node.setExpanded(true);
      }
    });
  }, []);

  const expandChildGroup = useCallback(() => {
    if (gridRef && gridRef.current && gridRef.current.api) {
      gridRef.current.api.forEachNode((node) => {
        if (props.childGroupDefaultOpenOptions.indexOf(node.key) !== -1) {
          node.parent.setExpanded(true); // ensure parent 'country' group is also expanded
          node.setExpanded(true);
        }
      });
    }
  }, []);

  const rowClassRules = useMemo(() => {
    const { apikey } = props;
    switch (apikey) {
      case "tb_pre_season_dtg":
        return {
          "editable-green": (params) => {
            return (
              props.isColorCodingRequired &&
              params.data &&
              params?.data?.["isEditable"] === "TRUE"
            );
          },
        };
      case "tb_prod_performance_last_13_wks":
        return {
          "val-above-four": (params) => {
            const difference = Math.abs(
              params?.data?.[props?.columnDifferences?.[0]] -
                params?.data?.[props?.columnDifferences?.[1]],
            );
            if (difference >= 4 && props.isColorCodingRequired && params.data) {
              return true;
            }
          },
        };
      case "tb_pre_season_wholesale":
        return {
          "editable-green": (params) => {
            return (
              props.isColorCodingRequired &&
              params.data &&
              params?.data?.["isEditable"] === "TRUE"
            );
          },
        };
      case "tb_target_planning_dtg":
        return {
          "editable-green": (params) => {
            return (
              props.isColorCodingRequired &&
              params.data &&
              params?.data?.["isEditable"] === "TRUE"
            );
          },
        };
      case "tb_plan_enable_pivot_2_test":
        return {
          "ia-forecast": (params) => {
            return (
              props.isColorCodingRequired &&
              params.data &&
              params.data["ref"] === "WP"
            );
          },
        };
      default:
        return {
          // row style function
          // "disabled-grey": (params) => {
          //   return (
          //     props.isColorCodingRequired &&
          //     params.data &&
          //     params?.data?.["disabled"] === "TRUE"
          //   );
          // },
          "ia-forecast": (params) => {
            return (
              props.isColorCodingRequired &&
              params.data &&
              (params.data["ref"] === "WP" || params.data["ref"] === "WF")
            );
          },
          "ia-assort-carryover": (params) => {
            return (
              props.isColorCodingRequired &&
              params.data &&
              (params.data["id"] === "8" ||
                params.data["id"] === "9" ||
                params.data["choice_id"] === "Total")
            );
          },
          "ia-assort-line-review": (params) => {
            return (
              props.isColorCodingRequired &&
              params.data &&
              (params.data["id"] === "3" ||
                params.data["identifier"] === "2" ||
                params.data["identifier"] === "3")
            );
          },
        };
    }
  });

  const aggFuncs = useMemo((params) => {
    return {
      mySum: (params) => {
        let sum = 0;
        params.values.forEach((value) => {
          return (sum +=
            typeof value === "number"
              ? value
              : parseInt(value?.replaceAll(",", "")));
        });
        return currencyFormatter({ value: sum });
      },
      myAvg: (params) => {
        let sum = 0;
        params.values.forEach((value) => {
          return (sum +=
            typeof value === "number"
              ? value
              : parseInt(value?.replaceAll(",", "")));
        });
        return currencyFormatter({ value: sum / params.values.length });
      },
      myAvgNormal: (params) => {
        let sum = 0;
        let valuesCount = 0;
        params.values.forEach((value) => {
          if (value !== "") {
            valuesCount++;
            sum +=
              typeof value === "number"
                ? value
                : parseInt(value?.replace(/,|\$|€/g, ""));
          }
        });
        if (params.colDef?.format === "cur") {
          return currencyFormatter({ value: sum / valuesCount });
        }
        return NoFormatter({ value: sum / valuesCount });
      },
      myFloatAvg: (params) => {
        let sum = 0;
        params.values.forEach((value) => {
          sum +=
            typeof value === "number"
              ? value
              : parseFloat(value?.replace(/,|\$/g, ""));
        });
        if (params.colDef?.format === "cur") {
          return currencyFormatter({ value: sum / params.values.length });
        }
        return parseFloat(sum / params.values.length).toFixed(2);
      },
      myMostFrequentString: (params) => {
        if (params?.values?.length > 0) {
          const mostFrequent = Array.from(new Set(params.values)).reduce(
            (prev, curr) =>
              params.values.filter((el) => el === curr).length >
              params.values.filter((el) => el === prev).length
                ? curr
                : prev,
          );
          return mostFrequent;
        }
        return null;
      },
      myAvgPercent: (params) => {
        let sum = 0;
        params.values.forEach((value) => {
          return (sum +=
            typeof value === "number"
              ? value
              : parseInt(value?.replaceAll(",", "")));
        });
        return percentageFormatter({ value: sum / params.values.length });
      },
      mySumNormal: (params) => {
        let sum = 0;
        let formatterType = null;
        console.log("***", params.rowNode.level);
        if (params.colDef.hasOwnProperty("maxAggLvl")) {
          const lvl = 1 - Math.max(1, params.colDef.maxAggLvl);
          if (lvl === params.rowNode.level) {
            return "";
          }
        }
        params.values.forEach((value) => {
          if (value !== "") {
            let updatedValue = value;
            if (value && value !== "number") {
              updatedValue = updatedValue?.replaceAll("%", "");
              updatedValue = updatedValue?.replaceAll(",", "");
              updatedValue = updatedValue?.replaceAll("$", "");
              updatedValue = updatedValue?.replaceAll("£", "");
              updatedValue = updatedValue?.replaceAll("€", "");
              updatedValue = +updatedValue;
              if (value.includes("%")) {
                formatterType = "percentageFormatter";
              } else if (
                value.includes("$") ||
                value.includes("€") ||
                value.includes("£")
              ) {
                formatterType = "currencyFormatter";
              }
            }
            sum += typeof value === "number" ? value : updatedValue;
          }
        });
        formatterType = params.colDef?.valueFormatterType || formatterType;
        if (formatterType === "percentageFormatter") {
          return percentageFormatter({ value: sum });
        } else if (formatterType === "currencyFormatter") {
          return currencyFormatter({ value: sum });
        } else {
          return NoFormatter({ value: sum });
        }
      },
      avgPercent: (params) => {
        let sum = 0;
        let formatterType = null;
        params.values.forEach((value) => {
          let updatedValue = value;
          if (value && value !== "number") {
            updatedValue = updatedValue?.replaceAll("%", "");
            updatedValue = updatedValue?.replaceAll(",", "");
            updatedValue = updatedValue?.replaceAll("$", "");
            updatedValue = updatedValue?.replaceAll("£", "");
            updatedValue = parseInt(updatedValue);
            if (value.includes("%")) {
              formatterType = "percentageFormatter";
            } else if (value.includes("$") || value.includes("£")) {
              formatterType = "currencyFormatter";
            }
          }
          return (sum += typeof value === "number" ? value : updatedValue);
        });

        if (formatterType === "percentageFormatter") {
          return percentageFormatter({ value: sum / params.values.length });
        } else if (formatterType === "currencyFormatter") {
          return currencyFormatter({ value: sum / params.values.length });
        } else {
          return NoFormatter({ value: sum / params.values.length });
        }
      },
      defaultAgg: (params) => {
        const defaultAggData = params?.colDef.aggData;
        return (
          defaultAggData[`${params.rowNode?.key}`] &&
          defaultAggData[`${params.rowNode?.key}`][`${params.colDef.field}`]
        );
      },
    };
  }, []);

  const createData = (props) => {
    let topPinnedRowData = [];
    props.pinnedTopRow.forEach((obj) => {
      let rowDataObj = {};
      if (
        tableInfo[`${props.apikey}`] &&
        tableInfo[`${props.apikey}`].length > 0
      ) {
        let rowInfo = tableInfo[`${props.apikey}`].filter((filterObj) => {
          return filterObj[`${obj.headerKey}`] === obj.headerName;
        });
        if (rowInfo.length > 0) {
          topPinnedRowData.push(rowInfo[0]);
        }
      }
    });
    return topPinnedRowData;
  };

  const calculateTotalRow = (totalRow, gridApi) => {
    if (!gridApi) return null;
    const fields = Object.keys(totalRow).filter(
      (key) => typeof totalRow[key] === "number",
    );
    gridApi.forEachNode((node) => {
      fields.forEach((key) => {
        totalRow[key] += cellValueToNumber(node.data[key]);
      });
    });
    return [totalRow];
  };

  const updatePinnedRowData = useCallback(
    (params) => {
      if (props.pinnedTopRow && props.pinnedTopRow.length > 0) {
        setPinnedTopRowData(createData(props));
      } else if (props?.totalRow) {
        setPinnedTopRowData(
          calculateTotalRow({ ...props.totalRow }, params.api),
        );
      }
    },
    [props],
  );

  // const detailCellRendererParams = useMemo(() => {
  const detailCellRendererParams = () => {
    return {
      detailGridOptions: {
        columnDefs: props.detailGridOptions.column,
        defaultColDef: {
          flex: 1,
        },
        onCellValueChanged: cellValueChanged,
      },
      getDetailRowData: (params) => {
        params.successCallback(
          tableInfo[`${props.detailGridOptions.key}`].filter(
            (obj) => obj.rowMasterKey === params.data.id,
          ),
        );
      },
    };
  };
  //   }, []);

  const isRowMaster = useMemo(() => {
    return (dataItem) => {
      return dataItem
        ? dataItem.isRowMaster === "TRUE"
          ? true
          : false
        : false;
    };
  }, []);

  function actionOnCellValueChange(params) {
    function actionInvoke(action, payload = {}) {
      if (action) {
        switch (action.actionName) {
          case "applyColumnSumToRows": {
            let columnSum = 0;
            params.api.forEachNode((node) => {
              columnSum += Number(node.data[params.colDef.field]);
            });
            const targetRowsId = new Set(action.rows);
            if (tableInfo[action.targetTableKey]) {
              const tableData = [...tableInfo[action.targetTableKey]];
              tableData.forEach((row) => {
                if (targetRowsId.has(row.id)) {
                  action.columns.forEach((field) => {
                    row[field] = columnSum;
                  });
                }
              });
              dispatch({
                type: "TABLE_DATA",
                payload: { [action.targetTableKey]: tableData },
              });
            }
            break;
          }
          case "spreadDeltaToRows": {
            function getChange(params) {
              const a = parseInt(params.newValue.replace(/,|%/g, ""));
              const b = parseInt(params.oldValue.replace(/,|%/g, ""));
              return a - b;
            }
            const delta = Math.round(
              (payload.type === "spreadDelta"
                ? payload.value
                : getChange(params)) / action.rows.length,
            );
            const targetRowsId = new Set(action.rows);
            if (tableInfo[action.targetTableKey]) {
              const tableData = [...tableInfo[action.targetTableKey]];
              tableData.forEach((row) => {
                if (targetRowsId.has(row.id)) {
                  action.columns.forEach((field) => {
                    const value = parseInt(row[field].replace(/,|%/g, ""));
                    row[field] = (value + delta).toString();
                  });
                }
              });
              dispatch({
                type: "TABLE_DATA",
                payload: { [action.targetTableKey]: tableData },
              });
            }
            if (action.nestedSpread) {
              action.nestedSpread.forEach((obj) => {
                actionInvoke(
                  { ...obj, actionName: "spreadDeltaToRows" },
                  { type: "spreadDelta", value: delta },
                );
              });
            }
            break;
          }
          case "distributeByPercentage": {
            const newValue = +params.newValue.replaceAll(",", "");
            if (isNaN(newValue)) return;
            const allGridRefs = gridStore.current;
            const targetTableKey = params.data["#target_table"];
            if (targetTableKey) {
              const targetGridApi = allGridRefs[targetTableKey]?.current?.api;
              if (targetGridApi) {
                targetGridApi.forEachNode((node) => {
                  if (node.data) {
                    if (!action.rows || action.rows.includes(node.data.id)) {
                      const perc = +node.data[action.percentageSourceColumn];
                      const result = (newValue * perc) / 100;
                      action.targetColumns.forEach((colId) => {
                        node.setDataValue(colId, result.toString());
                      });
                    }
                  }
                });
              }
            }
            break;
          }
          default:
            setTimeout(() => {
              let dynamicPayload = null;
              if (action.payloadType === "row") dynamicPayload = params.node;
              invokeAction(action, dynamicPayload);
            }, 0);
            break;
        }
      }
    }
    const action = params.colDef.actionOnCellValueChange;
    if (Array.isArray(action)) {
      action.forEach((obj) => actionInvoke(obj));
    } else {
      actionInvoke(action);
    }
  }

  const addGridExpander = useCallback(
    (gridApi, props) => {
      const sidePanelEl =
        gridApi.sideBarComp.eGui.querySelector(".ag-side-buttons");
      if (sidePanelEl) {
        sidePanelEl.ondblclick = (e) => {
          if (e.target === sidePanelEl) {
            if (heightExpanded) {
              setHeightExpanded(false);
              setGridHeight(props.height || "300px");
            } else {
              setHeightExpanded(true);
              setGridHeight(
                props.expandableHeight ||
                  `${containerRef.current.clientHeight * 2}px`,
              );
            }
          }
        };
      }
    },
    [heightExpanded],
  );
  useEffect(() => {
    if (gridApi) {
      addGridExpander(gridApi, props);
    }
  }, [gridApi, props, addGridExpander]);
  useEffect(() => {
    if (gridApi) {
      gridApi.onFilterChanged();
    }
  }, [tableFilterInfo, gridApi]);

  useEffect(() => {
    gridStore.current = gridRefs;
  }, [gridRefs]);

  return (
    <>
      {open ? (
        <CustomModal
          show={open}
          {...modalInfo}
          handleClose={() => {
            dispatch({
              type: "SET_TABLE_MODAL",
              payload: { [props.apikey]: { open: false, modalInfo } },
            });
          }}
        />
      ) : null}
      <div
        ref={containerRef}
        id="myGrid"
        style={{
          height: gridHeight,
          width: props.width || "100%",
        }}
        className={
          isImagePresent()
            ? "ag-theme-custom-react-image impact-table-container-style"
            : "ag-theme-custom-react impact-table-container-style"
        }
      >
        <AgGridReact
          ref={gridRef}
          {...props}
          onGridReady={onGridReady}
          rowSelection={props.rowSelection}
          suppressRowClickSelection={true}
          columnDefs={columnDefs}
          defaultColDef={{ ...defaultColDef }}
          groupSelectsChildren={props?.groupSelectsChildren !== false}
          pagination={props.pagination}
          paginationAutoPageSize={true}
          paginateChildRows={true}
          // autoGroupColumnDef={props.autoGroupColumnDef}
          // autoGroupColumnDef={props?.groupColumnName?props.autoGroupColumnDef:{headerName: "abc"}}
          autoGroupColumnDef={
            props.groupDisplayType === "multipleColumns"
              ? {
                  headerValueGetter: (params) => `${params.colDef.headerName}`,
                  minWidth: props.groupColumnWidth || 220,
                  pinned: props.groupPinned || false,
                  cellRendererParams: {
                    checkbox: props.groupCheckbox || false,
                  },
                }
              : props?.groupColumnName
                ? props.autoGroupColumnDef
                : { headerName: "" }
          }
          rowData={props.childTableData || rowData}
          headerHeight={headerHeight || 37}
          rowHeight={rowHeight || null}
          // masterDetail={true}
          aggFuncs={aggFuncs}
          suppressAggFuncInHeader={true}
          onFirstDataRendered={onFirstDataRendered}
          floatingFilter={props.floatingFilter}
          cacheQuickFilter={true}
          suppressMenuHide={true}
          getRowStyle={getRowStyle}
          frameworkComponents={{
            CustomRenderComponent: CustomRendererComponent,
            EditWeekData: EditWeekData,
            EditTextField: (params) => EditTextField(params, props),
            CellTextField: CellTextField,
            CellImageField: CellImageField,
            CellPopOverImageField: CellPopOverImageField,
            DropdownCellRenderer: DropdownCellRenderer,
            CellSwitchField: CellSwitchField,
            PreformattedText: PreformattedText,
            CellAddIcon: CellAddIcon,
            CellInfoIcon: CellInfoIcon,
            CellMultiSelectDropdown: CellMultiSelectDropdown,
            CellChips: CellChips,
            CircularColorChips: CircularColorChips,
            CellColor: CellColor,
            ColumnColorRange: ColumnColorRange,
            CellPopOverAction: CellPopOverAction,
            CellMultipleSelect: CellMultipleSelect,
            CellDropdownMap: CellDropdownMap,
            CellDropdownMulti: CellDropdownMulti,
            CellGroupSet: CellGroupSet,
            CellRendererSelector: CellRendererSelector,
            CustomMultiComponentCell: CustomMultiComponentCell,
            CellCompute: CellCompute,
            CustomGroupCellRenderer: GroupCellRenderer,
            CellToggle: CellToggle,
            CellDateRange: CellDateRange,
            NestedTable: NestedTable,
            CellCheckboxField: CellCheckboxField,
          }}
          components={{
            cellInfoTag: CellInfoTag,
            customCellValueRenderer: CustomCellValueRenderer,
            groupDependentRenderer: GroupValueSwitch,
            cellCheck: CellCheck,
          }}
          onCellValueChanged={cellValueChanged}
          onCellClicked={onCellClicked}
          context={context}
          onSelectionChanged={onSelectionChanged}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          {...gridOptions}
          rowClassRules={rowClassRules}
          pinnedTopRowData={pinnedTopRowData}
          // sideBar={(props.sideBar || props.showHideMetrics ) && sideBar}
          sideBar={
            props.sidePanel
              ? sidePanel
              : (props.sideBar === undefined || !!props.sideBar
                  ? true
                  : false) &&
                (props.showHideMetrics === undefined || !!props.showHideMetrics
                  ? true
                  : false) &&
                sideBar
          }
          masterDetail={
            props.detailGridOptions &&
            Object.keys(props.detailGridOptions).length > 0
              ? true
              : false
          }
          isRowMaster={
            props.detailGridOptions &&
            Object.keys(props.detailGridOptions).length > 0
              ? isRowMaster
              : null
          }
          detailCellRendererParams={
            props.detailGridOptions &&
            Object.keys(props.detailGridOptions).length > 0
              ? detailCellRendererParams
              : null
          }
          onRowDataChanged={onRowDataChanged}
          tooltipShowDelay={500}
          getMainMenuItems={(params) => getMainMenuItems(params, props)}
          // pinnedTopRowData={pinnedTopRowData}
          rowGroupPanelShow={props.rowGroupPanelShow || "never"}
          suppressDragLeaveHidesColumns={true}
          grandTotalRow={"bottom"}
        />
      </div>
    </>
  );
};

export default Table;

function columnRowGroupChanged(params, props, invokeAction) {
  const { rowGroupPanelLimit, actionOnColumnRowGroupChanged } = props;
  if (rowGroupPanelLimit) {
    const newRowGroupColumns = params.columnApi.getRowGroupColumns();
    if (newRowGroupColumns.length > rowGroupPanelLimit) {
      const leftMostGroupInPanel = newRowGroupColumns[0];
      params.columnApi.removeRowGroupColumns([leftMostGroupInPanel.getColId()]);
    }
    params.columnApi.getRowGroupColumns().forEach((column) => {
      params.columnApi.setColumnVisible(column, false);
    });
  }
  invokeAction(actionOnColumnRowGroupChanged, params);
}

function isSingleGroupSelected(selectedNodes) {
  const groupCol = selectedNodes[0]?.parent?.id;
  if (groupCol) {
    for (let i = 1; i < selectedNodes.length; i++) {
      if (selectedNodes[i].parent.id !== groupCol) {
        return false;
      }
    }
    return true;
  }
  return false;
}

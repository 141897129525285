import React, { useState } from "react";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Rnd } from "react-rnd";
import CustomActionButton from "../../pages/Home/CustomComponents/CustomActionButton";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const DraggableCoreComponent = (props) => {
  const {
    widthValue,
    heightValue,
    text,
    fill,
    label,
    labelIndicator,
    labelStyle,
    x,
    y,
    fixed,
    freezeAll,
    setFreeze,
  } = props;
  const [width, setWidth] = useState(widthValue);
  const [height, setHeight] = useState(heightValue);
  const [position, setPosition] = useState({
    x: props.x,
    y: props.y + 10,
  });

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: fill,
    ...props.blockStyle
  };

  const BlockRenderer = (type) => {
    switch (type) {
      case "rect":
        return (
          <>
            <Rnd
              style={style}
              default={position}
              // default={{ x: x, y: props.y + 10 }}
              size={{ width: width, height: height }}
              onResizeStop={(e, direction, ref, delta, position) => {
                setWidth(ref.style.width);
                setHeight(ref.style.height);
              }}
              // onDragStop={(e, d) => {
              //   if (props.fixed) {
              //     setPosition({
              //       x: props.x,
              //       y: props.y + 10,
              //     });
              //   }
              // }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "Center",
                  rotate: props.rotate || "0deg",
                  border: props.border || "",
                  padding: props.padding || "",
                  ...props.style,
                }}
              >
                {/* <span onClick={setFreeze} className="lock">
          {fixed || freezeAll ? <LockIcon /> : <LockOpenIcon />}
        </span> */}
                {text && (
                  <div
                    style={{
                      width: "max-content",
                      fontSize: 13,
                      ...props?.textStyle,
                    }}
                  >
                    {text}
                  </div>
                )}
                {props.actionButton && (
                  <CustomActionButton data={props.actionButton} />
                )}
              </div>
            </Rnd>
          </>
        );
      case "cicle":
        return (
          <>
            {label ? (
              <h5 style={labelStyle}>
                {labelIndicator === "high" ? (
                  <>
                    <KeyboardDoubleArrowUpIcon
                      style={{ marginBottom: "-5px" }}
                    />
                    + {label}
                  </>
                ) : (
                  <>
                    <KeyboardDoubleArrowDownIcon
                      style={{ marginBottom: "-5px" }}
                    />
                    - {label}
                  </>
                )}
              </h5>
            ) : null}
            <Rnd
              style={{
                borderRadius: "50%",
                background: props?.fill,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ...props.style,
              }}
              default={{ x: x, y: props.y + 10 }}
              size={{
                width: props.widthValue,
                height: props.widthValue,
              }}
            >
              {text && (
                <p style={{ fontSize: 12, ...props?.textStyle }}>{text}</p>
              )}
            </Rnd>
          </>
        );
      case "line":
        return (
          <>
            <Rnd
              default={{ x: x, y: props.y + 10 }}
              size={{
                width: props.widthValue,
                height: props.heightValue,
              }}
            >
              <div
                style={{
                  background: props?.fill,
                  rotate: props.rotate || "0deg",
                  border: props.border || "",
                  padding: props.padding || "",
                  width: props.widthValue,
                  height: props.heightValue,
                  ...props.style,
                }}
              ></div>
            </Rnd>
          </>
        );
      case "label":
        return (
          <>
            <Rnd
              style={style}
              default={position}
              // default={{ x: x, y: props.y + 10 }}
              size={{ width: width, height: height }}
              onResizeStop={(e, direction, ref, delta, position) => {
                setWidth(ref.style.width);
                setHeight(ref.style.height);
              }}
              // onDragStop={(e, d) => {
              //   if (props.fixed) {
              //     setPosition({
              //       x: props.x,
              //       y: props.y + 10,
              //     });
              //   }
              // }}
            >
              <div
                style={{
                  width: "max-content",
                  fontSize: 13,
                  display: "flex",
                  alignItems: "Center",
                  rotate: props.rotate || "0deg",
                  ...props?.labelStyle,
                }}
              >
                {labelIndicator === "high" ? (
                  <KeyboardDoubleArrowUpIcon />
                ) : (
                  !props?.indicatorNotRequired && <KeyboardDoubleArrowDownIcon />
                )}{" "}
                {label}
              </div>
            </Rnd>
          </>
        );
      default:
        return (
          <>
            {label ? (
              <h5 style={labelStyle}>
                {labelIndicator === "high" ? (
                  <>
                    <KeyboardDoubleArrowUpIcon
                      style={{ marginBottom: "-5px" }}
                    />
                    + {label}
                  </>
                ) : (
                  <>
                    <KeyboardDoubleArrowDownIcon
                      style={{ marginBottom: "-5px" }}
                    />
                    - {label}
                  </>
                )}
              </h5>
            ) : null}
            <Rnd
              style={style}
              default={{ x: x, y: props.y + 10 }}
              size={{ width: width, height: height }}
              onResizeStop={(e, direction, ref, delta, position) => {
                setWidth(ref.style.width);
                setHeight(ref.style.height);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "Center",
                  rotate: props.rotate || "0deg",
                  border: props.border || "none",
                  padding: props.padding || "",
                  ...props.style,
                }}
              >
                <img
                  src={require(`../../assets/${props.path}`)}
                  style={{
                    width: props.widthValue,
                    height: props.heightValue,
                    ...props.imageStyle,
                  }}
                />
              </div>
            </Rnd>
          </>
        );
    }
  };
  return (
    <>
      {/* {label ? (
        <h5 style={labelStyle}>
          {labelIndicator === "high" ? (
            <>
              <KeyboardDoubleArrowUpIcon style={{ marginBottom: "-5px" }} />+{" "}
              {label}
            </>
          ) : (
            <>
              <KeyboardDoubleArrowDownIcon style={{ marginBottom: "-5px" }} />-{" "}
              {label}
            </>
          )}
        </h5>
      ) : (
        ""
      )} */}
      {/* {
        props.type === "rect" ? (
          <Rnd
            style={style}
            default={{ x: x, y: props.y + 10 }}
            size={{ width: width, height: height }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setWidth(ref.style.width);
              setHeight(ref.style.height);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "Center",
                rotate: props.rotate || "0deg",
                border: props.border || "",
                padding: props.padding || "",
              }}
            >
              <span onClick={setFreeze} className="lock">
          {fixed || freezeAll ? <LockIcon /> : <LockOpenIcon />}
        </span>
              {text && (
                <div
                  style={{
                    width: "max-content",
                    fontSize: 13,
                    ...props?.textStyle,
                  }}
                >
                  {text}
                </div>
              )}
              {props.actionButton && (
                <CustomActionButton data={props.actionButton} />
              )}
            </div>
          </Rnd>
        ) : (
          <Rnd
            style={{
              width: "100px",
              borderRadius: "50%",
              minHeight: "100px",
              background: "red",
            }}
            default={{ x: x, y: props.y + 10 }}
            size={{ width: "100px", height: "100px" }}
          >
            s
          </Rnd>
        )

        <Rnd
          style={style}
          default={{ x: x, y: props.y + 10 }}
          size={{ width: width, height: height }}
          onResizeStop={(e, direction, ref, delta, position) => {
            setWidth(ref.style.width);
            setHeight(ref.style.height);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "Center",
              rotate: props.rotate || "0deg",
              border: props.border || "",
              padding:props.padding || ""

            }}
          >
            <img src={require(`../../assets/${props.path}`)} style={{width: props.widthValue, height: props.heightValue, ...props.imageStyle}} />
          </div>
        </Rnd>
      } */}
      {BlockRenderer(props.type)}
    </>
  );
};

export default DraggableCoreComponent;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormControlLabel, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { CustomModal } from "./customModal";
import AddIcon from "@mui/icons-material/Add";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import SnackBar from "../../../components/SnackBar";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import SaveIcon from "@mui/icons-material/Save";
import CachedIcon from "@mui/icons-material/Cached";
import DownloadFiles from "./DownloadFiles";
import _ from "lodash";
import { addNotifications } from "../data";
import ButtonDropdown from "../CustomComponents/ButtonDropdown";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Text from "../../../utils/Text";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SettingsIcon from "@mui/icons-material/Settings";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useEffect } from "react";
import useAction from "../../../utils/useAction";
import UploadIcon from "@mui/icons-material/Upload";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TokenIcon from "@mui/icons-material/Token";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ReplayIcon from "@mui/icons-material/Replay";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import UndoIcon from "@mui/icons-material/Undo";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CalculateIcon from "@mui/icons-material/Calculate";
import CompareIcon from "@mui/icons-material/Compare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const disableLinkStyle = {
  pointerEvents: "none",
  color: "grey",
  textDecoration: "none",
};
const CustomActionButton = (props) => {
  const { data } = props;
  const params = props?.otherInfo?.params;
  const {
    selectedRowInfo,
    tableInfo,
    editActionInfo,
    dependentInfo,
    formEditedValues,
    columnsInfo,
    gridRefs,
    dropdownInfo,
    tableInfoSaved,
    dropdownSelectionData,
    checkedRows,
    buffer,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invokeAction = useAction();
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [itemIndex, setItemIndex] = useState(null);
  const [cellActionIndex, setCellActionIndex] = useState(() => {
    if (props?.otherInfo?.data) {
      const actionIndex = props?.otherInfo?.data["#actionIndex"];
      if (actionIndex !== "") {
        return parseInt(actionIndex);
      }
    }
    return null;
  });
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const onAction = (action, index) => {
    if (!action) {
      return true;
    }
    if (action?.actionName === "navigate") {
      if (action.otherActionName === "notifications") {
        addNotifications(action);
      }
      const _path = props?.otherInfo?.data["#path"];
      navigate(
        "/" +
          window?.location?.pathname?.split("/")[1] +
          "/" +
          window?.location?.pathname?.split("/")[2] +
          (_path || action.path)
      );
      if (action.sortBasedOnDropdown === "SORT_BASED_ON_DROPDOWN") {
        let tableParams = {};
        let tableData = [];
        if (dependentInfo[`${action.key1}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key1}`];
        }
        if (dependentInfo[`${action.key2}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key2}`];
        }
        tableParams[`${action.parent_table_key}`] = tableData;
        dispatch({
          type: "TABLE_DATA",
          payload: tableParams,
        });
      }
    } else if (action.actionName === "modal") {
      if (action.disableTabSwitch === true) {
        dispatch({
          type: "SHOW_ACTIVE_TAB",
          showActiveTab: false,
        });
      }
      setOpen(!open);
      setItemIndex(index);
      if (action.isStoreRequired) {
        let params = {};
        params[`${action.key}`] = props.otherInfo.uniqueKey;
        params[`selectedRowIndex`] = props.otherInfo["rowIndex"];
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: params,
        });
      }
    } else if (action.actionName === "Download") {
      dispatch({
        type: "EXPORT_TO_EXCEL",
        payload: true,
      });
    } else if (action.actionName === "click") {
      let params = {};

      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        // if(action.otherKey){
        params[`${action.otherKey}`] = true;
        // }
      }
      if (action.hasOwnProperty("defaultToggle")) {
        params[`${action.key}`] = action.defaultToggle;
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      if (action.type === "tab-redirect") {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
          },
        });
      }
    } else if (action.actionName === "tab-redirect") {
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      if (action.tabHierarchyLevel) {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabValues: action.activeTabValues,
            activeTabIndexes: action.activeTabIndexes,
            tabHierarchyLevel: action.tabHierarchyLevel,
          },
        });
      } else {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
          },
        });
      }
    } else if (action.actionName === "RECALCULATE_IA_FORECAST") {
      let metricData = [];
      let payload = {};
      if (action["source_table_key"] && action["parent_table_key"]) {
        metricData = tableInfo[`${action.source_table_key}`];
        payload[`${action.parent_table_key}`] = [...metricData];
      } else {
        metricData = tableInfo["tb_plan_department_recalculate_ia_forecast"];
        if (metricData) {
          payload[`tb_plan_department`] = [...metricData];
        }
      }
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "RECALCULATE_WHOLESALE_IA_FORECAST") {
      let metricData = [];
      let payload = {};
      if (action["source_table_key"] && action["parent_table_key"]) {
        metricData = tableInfo[`${action.source_table_key}`];
        payload[`${action.parent_table_key}`] = [...metricData];
      } else {
        metricData =
          tableInfo["tb_plan_department_calculate_wholesale_ia_forecast"];
        if (metricData) {
          payload[`tb_plan_department_preseason_wholesale`] = [...metricData];
        }
      }
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "STYLE_SHOW_HIDE") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`style-save`] = false;
      params[`add-style-button`] = false;
      params[`action-bar-2`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "STYLE_SHOW_HIDE-2") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`style-save-2`] = false;
      params[`add-style-button-2`] = false;
      params[`action-bar-2`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "RECOMMENDED_TABLE_SHOW_HIDE") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`recommended-table-1`] = false;
      params[`recommended-table-2`] = true;
      params[`store_list_new_col`] = false;
      params[`recommended-labels`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "BUTTON_VALUE_CLICK") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`store_list_new_col`] = true;
      params[`recommended-table-1`] = false;
      params[`recommended-labels`] = true;
      params[`import-new-table`] = false;
      params[`update-attributes-table`] = false;
      params[`166`] = false;
      params[`167`] = false;
      params[`update-attributes-table`] = false;
      params[`166`] = false;
      params[`initial-load`] = false;
      params[`280`] = false;
      params[`281`] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "CLOSE_BUTTON_CLICK") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`store_list_new_col`] = false;
      params[`recommended-table-1`] = true;
      params[`recommended-table-2`] = false;
      params[`recommended-labels`] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "delete") {
      dispatch({
        type: "DELETE_SELECTED_TABLE_ROW",
        payload: true,
      });
      const tableData = tableInfo[`${action.parent_table_key}`];
      const selectedRowIndexes = new Set(
        gridRefs[action.parent_table_key].current.api
          .getSelectedNodes()
          .map((node) => node.rowIndex)
      );
      // const selectedRowIds = selectedRowInfo[`${action.parent_table_key}`].map(
      //   (obj) => obj.id
      // );
      const updatedData = tableData.filter((obj, index) => {
        return !selectedRowIndexes.has(index);
      });
      let payload = {};
      payload[`${action.parent_table_key}`] = [...updatedData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "edit") {
      let params = {};
      if (editActionInfo[`${action.parent_table_key}`]) {
        params[`${action.parent_table_key}`] = null;
      } else {
        params[`${action.parent_table_key}`] = true;
      }
      dispatch({
        type: "EDIT_ACTION",
        payload: params,
      });
    } else if (action.actionName === "Add") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.parent_table_key}`];
      if (tableData && tableData.length > 0) {
        let rowObj = {};
        Object.keys(tableData[0]).forEach((obj) => {
          rowObj[`${obj}`] = null;
          rowObj[`id`] = tableData.length + 1;
          rowObj[`department`] = "Skin Care";
          rowObj[`type`] = "Department";
        });
        newRow.push(rowObj);
      } else {
        newRow.push(action.defaultValue);
      }
      params[`${action.parent_table_key}`] = [...tableData, ...newRow];
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "REFRESH_DATA") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.parent_table_key}`];
      params[`${action.source}`] = tableInfo[`${action.parent_table_key}`];
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "displayMessage") {
      setMessage(action.message);
      setShowMessage(true);
      setAction(action);
      if (action.otherActionName === "notifications") {
        addNotifications(action);
      }
      if (action.path) {
        setTimeout(() => {
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              action.path
          );
        }, 2000);
      }
      if (action.sortBasedOnDropdown === "SORT_BASED_ON_DROPDOWN") {
        let tableParams = {};
        let tableData = [];
        if (dependentInfo[`${action.key1}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key1}`];
        }
        if (dependentInfo[`${action.key2}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key2}`];
        }
        tableParams[`${action.parent_table_key}`] = tableData;
        dispatch({
          type: "TABLE_DATA",
          payload: tableParams,
        });
      }
    } else if (action.actionName === "SHOW_CLUSTER") {
      let params = {};
      params[`showClusterLevelPLan`] = true;
      params[`dinnerware-store-cluster`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "APPLY_FILTER_ACTION") {
      let params = {};
      params[`apply-action`] = true;
      params[`default-key`] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "CustomDisplayToggle") {
      let params = {};
      if (action.key === "toggle") {
        params[`default-key`] = false;
        params[`toggle`] = true;
      } else if (action.key === "default-key") {
        params[`default-key`] = true;
        params[`toggle`] = false;
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "ENABLE_DISABLE_KEYS") {
      let params = {};
      params[action.key] = true;
      params[action.otherKey] = false;
      params[action.otherKey2] = action.otherKey2 ? false : null;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "ENABLE_DISABLE_BUTTON_KEYS") {
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      let params = {};
      params[action.key] = true;
      params[action.key1] = action.key1 ? true : null;
      params[action.key2] = action.key2 ? true : null;
      params[action.key3] = action.key3 ? true : null;
      params[action.key4] = action.key4 ? true : null;
      params[action.key5] = action.key5 ? true : null;
      params[action.key6] = action.key6 ? true : null;
      params[action.otherKey1] = action.otherKey1 ? false : null;
      params[action.otherKey2] = action.otherKey2 ? false : null;
      params[action.otherKey3] = action.otherKey3 ? false : null;
      params[action.otherKey4] = action.otherKey4 ? false : null;
      params[action.otherKey5] = action.otherKey5 ? false : null;
      params[action.otherKey6] = action.otherKey6 ? false : null;
      params[action.otherKey7] = action.otherKey7 ? false : null;
      params[action.otherKey8] = action.otherKey8 ? false : null;
      params[action.otherKey9] = action.otherKey9 ? false : null;
      params[action.otherKey10] = action.otherKey10 ? false : null;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
      // Feature implementing conditional navigation based on number of rows selected in a table
    } else if (action.actionName === "ROW_SELECTION") {
      if (
        checkedRows[action.selectionKey] &&
        checkedRows[action.selectionKey].length >= 1
      ) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.rowSelected.path
        );
      } else {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.noRowSelected.path
        );
      }
    } else if (action.actionName === "SINGLE_SELECT") {
      if (
        checkedRows[action.selectionKey] &&
        checkedRows[action.selectionKey].length === 1
      ) {
        let params = {};
        params[action.singleSelect] = true;
        params["default-key"] = false;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      navigate(
        "/" +
          window?.location?.pathname?.split("/")[1] +
          "/" +
          window?.location?.pathname?.split("/")[2] +
          action.path
      );
    } else if (action.actionName === "IN_SEASON_RECALCULATE_IA_FORECAST") {
      const metricData = tableInfo[`${action.source}`];
      let payload = {};
      payload[`${action.dest}`] = [...metricData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "notifications") {
      addNotifications(action);
    } else if (action.actionName === "DATA_REFRESH_BASED_ON_DROPDOWN") {
      let payload = {};
      let sourceTableData = tableInfo[`${action.source_table_key}`];
      action.toggleOptions.forEach((obj) => {
        if (action["isBasedOnStore"]) {
          if (dependentInfo[`${obj}`]) {
            sourceTableData = tableInfo[`${action[`${obj}`]}`];
          }
        } else {
          if (formEditedValues[`${obj}`]) {
            sourceTableData = tableInfo[`${action[`${obj}`]}`];
          }
        }
      });
      payload[`${action.parent_table_key}`] = sourceTableData;
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "TAB_REDIRECT_BASED_ON_DROPDOWN") {
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      if (
        action["isBasedOnDropdownKey"] &&
        formEditedValues[`${action.key}`] === action["isBasedOnDropdownKey"]
      ) {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
            isDependentOn: action.isDependentOn,
          },
        });
        setTimeout(() => {
          dispatch({
            type: "RESET_TAB_REDIRECT",
            payload: {},
          });
        }, 15000);
      }
    } else if (action.actionName === "showActiveTab") {
      dispatch({
        type: "SHOW_ACTIVE_TAB",
        payload: action,
      });
    } else if (action.actionName === "redirectNewTab") {
      dispatch({
        type: "REDIRECT_TO_NEW_TAB",
        payload: action,
      });
    } else if (action.actionName === "COPY_ONE_TABLE_TO_ANOTHER") {
      const sourceData = tableInfo[`${action.source_table_key}`];
      let payload = {};
      payload[`${action.parent_table_key}`] = [...sourceData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
      if (action["dependentOn"]) {
        let params = {};
        params[`${action.dependentOn.key}`] = true;
        params[`${action.dependentOn.otherKey}`] = false;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      if (action.key || action.otherKey) {
        let params = {};
        params[action.key] = true;
        params[action.key1] = action.key1 ? true : null;
        params[action.key2] = action.key2 ? true : null;
        params[action.otherKey] = action.otherKey ? false : null;
        params[action.otherKey1] = action.otherKey1 ? false : null;
        params[action.otherKey2] = action.otherKey2 ? false : null;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      if (action.type === "tab-redirect") {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
          },
        });
      }
    } else if (action.actionName === "APPEND_NEW_DATA_TO_TABLE") {
      const sourceData = tableInfo[`${action.source_table_key}`];
      const parentData = tableInfo[`${action.parent_table_key}`];
      let payload = {};
      if (action.idUpdateRequired) {
        let currentId = sourceData?.length;
        const processedData = sourceData?.map((row) => {
          const rowData = {
            ...row,
            id: currentId,
          };
          currentId++;
          if (action.hasOwnProperty("clearValueFor")) {
            action?.clearValueFor?.forEach((propertyName) => {
              if (rowData.hasOwnProperty(propertyName)) {
                rowData[propertyName] = "";
              }
            });
          }
          return rowData;
        });
        payload[`${action.parent_table_key}`] = [
          ...parentData,
          ...processedData,
        ];
      } else {
        payload[`${action.parent_table_key}`] = [...parentData, ...sourceData];
      }
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "FILTER_DATA_BASED_ON_KEY") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.source}`];
      // const updatedData = tableData.filter(obj=> obj[`${action.mappingKey}`].includes(`(${formEditedValues[`${action.data_key}`]}%)`) )

      const updatedData = tableData.filter((obj) => {
        return (
          obj[`${action.mappingKey}`] >= formEditedValues[`${action.data_key}`]
        );
      });
      params[`${action.destination}`] = updatedData;
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "RESET_DATA") {
      let params = {};
      const tableData = tableInfo[`${action.source}`];
      params[`${action.destination}`] = tableData;
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "PAGE_OPEN_DEPENDENT_ON_KEYS") {
      let params = {};
      if (action.dependentOnClick) {
        params[`${action.dependentOnClick}`] = true;
      }
      if (action.default_table_data) {
        params[`${action.default_table_data}`] = action.default_table_data;
      }
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
      if (action["activeTabIndex"]) {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
            isDependentOn: action.isDependentOn,
          },
        });
        setTimeout(() => {
          dispatch({
            type: "RESET_TAB_REDIRECT",
            payload: {},
          });
        }, 15000);
      }
    } else if (
      action.actionName === "TABLE_AND_GRAPH_DATA_REFRESH_BASED_FILTER"
    ) {
      const columnsList = columnsInfo[`${action.key}`];
      let reqParams = {};
      let selectedValues = formEditedValues[`${action.mappingKey}`];
      if (selectedValues && selectedValues.length > 0) {
        action.options.forEach((colObj) => {
          if (selectedValues[0]["value"] === colObj.value) {
            columnsList.filter((col) => {
              if (col.headerName === colObj.label) {
                col.hide = false;
              }
            });
          } else {
            columnsList.filter((col) => {
              if (col.headerName === colObj.label) {
                col.hide = true;
              }
            });
          }
        });
        let chartInfo = tableInfo[`${action.source_graph_api_key}`];
        if (chartInfo && chartInfo.length > 0) {
          if (action.mappingKey && formEditedValues[`${action.mappingKey}`]) {
            chartInfo = chartInfo.filter(
              (obj) =>
                selectedValues
                  .map((selectedObj) => selectedObj.value)
                  .indexOf(obj[`${action.mappingKeyColumn}`]) !== -1
            );
          }
          if (
            action.otherMappingKey &&
            formEditedValues[`${action.otherMappingKey}`]
          ) {
            chartInfo = chartInfo.filter(
              (obj) =>
                formEditedValues[`${action.otherMappingKey}`]
                  .map((selectedObj) => selectedObj.value)
                  .indexOf(obj[`${action.otherMappingKeyColumn}`]) !== -1
            );
            action.otherMappingOptions.forEach((colObj) => {
              if (
                formEditedValues[`${action.otherMappingKey}`][0]["value"] ===
                colObj.value
              ) {
                columnsList.filter((col) => {
                  if (col.headerName === colObj.label) {
                    col.hide = false;
                  }
                });
              } else {
                columnsList.filter((col) => {
                  if (col.headerName === colObj.label) {
                    col.hide = true;
                  }
                });
              }
            });
          }
          let metricValues = formEditedValues[`${action.metricKey}`].map(
            (obj) => obj.value
          );
          let xaxis = {
            categories: _.uniq(chartInfo.map((obj) => obj.xAxis)),
          };
          if (metricValues[0] === "Avg") {
            xaxis = {
              categories: _.uniq(
                chartInfo
                  .filter((obj) => obj.xAxis !== "Total")
                  .map((obj) => obj.xAxis)
              ).concat(["Avg"]),
            };
          }
          let yaxis = {
            categories: _.uniq(
              chartInfo.map((obj) => obj[`${action.otherMappingKeyColumn}`])
            ),
            tickInterval: action.tickInterval,
          };
          let groupedData = _.groupBy(chartInfo, "name");
          let chartData = [];
          let reqParams = {};
          // debugger
          if (Object.keys(groupedData).length > 0) {
            Object.keys(groupedData).forEach((groupObj) => {
              let chartObj = { ...groupedData[`${groupObj}`][0] };
              const totalValue = groupedData[`${groupObj}`]
                .filter((obj) => obj.xAxis === "Total")
                .map((obj) => parseInt(obj.value))[0];
              chartObj["data"] = groupedData[`${groupObj}`].map((filterObj) => {
                if (metricValues[0] === "% Column") {
                  return (parseInt(filterObj.value) / totalValue) * 100;
                } else if (
                  metricValues[0] === "Avg" &&
                  filterObj.xAxis === "Total"
                ) {
                  chartObj.name_copy = "Avg";
                  let allValues = groupedData[`${groupObj}`]
                    .filter((obj) => obj.xAxis !== "Total")
                    .map((obj) => parseInt(obj.value));
                  return _.sum(allValues) / allValues.length;
                } else {
                  return parseInt(filterObj.value);
                }
              });
              chartData.push({
                name: chartObj.name,
                color: chartObj.color,
                data: chartObj.data,
              });
            });
          }
          reqParams["data"] = chartData;
          chartData = chartData;
          let params = {};
          let formEditing = {};
          params[`${action.graph_api_key}`] = chartData;
          params[`${action.graph_api_key}_copy`] = [
            ...tableInfo[`${action.source_graph_api_key}`],
          ];
          formEditing[`${action.graph_api_key}_chart`] = {
            yaxis: yaxis,
            xaxis: xaxis,
          };
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditing,
          });

          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        reqParams[`${action.key}`] = columnsList;
        dispatch({
          type: "COLUMN_DATA",
          payload: reqParams,
        });
      }
    } else if (action.actionName === "SET_TABLE_DATA_FOR_MODAL") {
      if (
        selectedRowInfo[`${action.source_table_key}`] &&
        selectedRowInfo[`${action.source_table_key}`].length > 0
      ) {
        const selectedRowData =
          selectedRowInfo[`${action.source_table_key}`][0];
        let rowObj = tableInfo[`${action.source_table_key}`].filter(
          (obj) => obj.id === selectedRowData.id
        )[0];
        let bulkEditData = tableInfo[`${action.destination_table_key}`].filter(
          (obj) => {
            if (action.conditionValues.indexOf(obj[`name`]) !== -1) {
              action.valuesUpdateOn.forEach((updateObj) => {
                switch (obj.name) {
                  case "Min":
                    obj[`${updateObj.colName}`] = Math.round(
                      (rowObj[`min_allocation`] * updateObj.percentage) / 100
                    );
                    break;
                  case "ST%":
                    obj[`${updateObj.colName}`] = Math.round(
                      (rowObj[`st%_allocation`] * updateObj.percentage) / 100
                    );
                    break;
                  case "Allocate Quantity":
                    obj[`${updateObj.colName}`] = Math.round(
                      (rowObj[`total_allocated_units`] * updateObj.percentage) /
                        100
                    );
                    break;
                  default:
                    break;
                }
              });
            }
            return obj;
          }
        );
        let params = {};
        params[`${action.destination_table_key}`] = bulkEditData;
        dispatch({
          type: "TABLE_DATA",
          payload: params,
        });

        if (action.otherActionName === "modal") {
          setOpen(!open);
          setItemIndex(index);
        }
      }
    } else if (action.actionName === "APPLY_PRIORITY") {
      let params = {};
      params[`${action.mappingKey}`] = formEditedValues[`${action.sourceKey}`];
      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: params,
      });
    } else if (action.actionName === "ADD_APPLICABLE_RULES") {
      let params = {};
      params[`${action.key}`] = true;
      params[`${action.otherKey}`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
      if (action.otherActionName === "modal") {
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: {
            isCloseModal: true,
          },
        });
        // setOpen(false);
        // setItemIndex(index);
      }
    } else if (action.actionName === "RESET_TABLE") {
      const gridRef = gridRefs[action.parent_table_key].current;
      gridRef.api.forEachNode((node) => node.setSelected(false));
      dispatch({
        type: "EDIT_ACTION",
        payload: { [action.parent_table_key]: false },
      });
    } else if (action.actionName === "SET_ROWS_DATA") {
      invokeAction(action);
    } else if (action.actionName === "SAVE_TABLE") {
      if (tableInfo[action.parent_table_key]) {
        dispatch({
          type: "TABLE_DATA_SAVED",
          payload: {
            [action.parent_table_key]: _.cloneDeep(
              tableInfo[action.parent_table_key]
            ),
          },
        });
      }
    } else if (action.actionName === "SET_HIDE_ROWS") {
      const rows = [...tableInfo[`${action.parent_table_key}`]];
      if (rows && rows.length > 0) {
        if (action?.byTag) {
          const { tag, visibility } = action.byTag;
          rows.forEach((row) => {
            if (row["#tag"] === tag) row["#hidden"] = visibility ? "N" : "Y";
          });
        } else {
          action.data.forEach(
            (obj) =>
              (rows[obj.rowIndex]["#hidden"] = obj.visibility ? "N" : "Y")
          );
        }
        dispatch({
          type: "TABLE_DATA",
          payload: { [action.parent_table_key]: [...rows] },
        });
      }
    } else if (action.actionName === "SET_OPTIONS_VISIBLE") {
      const options = new Set(dropdownInfo[action.dropdownId] || []);
      action?.info.forEach(({ id, visibility }) => {
        if (visibility) {
          options.add(id);
        } else {
          options.delete(id);
        }
      });
      dispatch({
        type: "DROPDOWN_INFO",
        payload: { [action.dropdownId]: [...options] },
      });
    } else if (action.actionName === "COPY_COLUMN_DATA_ACROSS") {
      const rowIndex = props?.otherInfo?.rowIndex || 0;
      const { source, dest } = action;
      const sourceTable = source.saved
        ? tableInfoSaved[source.table_key]
        : tableInfo[source.table_key];
      const destTable = [...tableInfo[dest.table_key]];
      if (sourceTable && sourceTable.length > 0) {
        const sourceRow = sourceTable[rowIndex];
        if (destTable && destTable.length > 0) {
          destTable.forEach((row) =>
            source.fields.forEach(
              (field, i) => (row[dest.fields[i]] = sourceRow[field])
            )
          );
          dispatch({
            type: "TABLE_DATA",
            payload: { [dest.table_key]: [...destTable] },
          });
        }
      }
    } else if (action.actionName === "ADD_ONE_TABLE_TO_ANOTHER") {
      const sourceData = tableInfo[`${action.source_table_key}`];
      let payload = {};
      payload[`${action.parent_table_key}`] = [
        ...tableInfo[`${action.parent_table_key}`],
        ...sourceData,
      ];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
      if (action["dependentOn"]) {
        let params = {};
        params[`${action.dependentOn.key}`] = true;
        params[`${action.dependentOn.otherKey}`] = false;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
    } else if (action.actionName === "DELETE_DEPENDENT_KEYS") {
      let params = { ...dependentInfo };
      action.deleteKeys.forEach((keyName) => {
        if (params[`${keyName}`]) {
          delete params[`${keyName}`];
        }
      });
      dispatch({
        type: "DELETE_DEPENDENT_COMPONENTS",
        payload: params,
      });

      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
    } else if (action.actionName === "ADD_NEW_ROW") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.parent_table_key}`];
      if (tableData && tableData.length > 0) {
        let rowObj = { ...tableData[0] };
        rowObj[`id`] = tableData.length + 1;
        if (action?.setData) {
          Object.keys(action.setData).forEach((key) => {
            const obj = action.setData[key];
            if (typeof obj === "object") {
              rowObj[key] = obj?.bufferKey ? buffer[obj.bufferKey] : obj.value;
            } else {
              rowObj[key] = obj;
            }
          });
        }
        newRow.push(rowObj);
        if (action.dest_table_key) {
          params[`${action.dest_table_key}`] = [
            ...tableInfo[action.dest_table_key],
            ...newRow,
          ];
        } else {
          params[`${action.parent_table_key}`] = [...tableData, ...newRow];
        }
        dispatch({
          type: "TABLE_DATA",
          payload: params,
        });
      }
    } else if (action.actionName === "APPEND_NEW_ROW") {
      const tableData = tableInfo[action.dest_table_key];
      if (tableData && tableData.length > 0) {
        const rows = [...tableData];
        const newRow = { ...rows[rows.length - 1] };
        if (action?.defaultValue) {
          Object.keys(newRow).forEach(
            (key) => (newRow[key] = action.defaultValue)
          );
        } else if (action?.columnValues) {
          Object.keys(newRow).forEach((key) => {
            if (action.columnValues.hasOwnProperty(key))
              newRow[key] = action.columnValues[key];
          });
        }
        if (action?.assignId) {
          newRow["id"] = rows?.length + 1;
        }
        rows.push(newRow);
        console.log("test4", rows, newRow);
        dispatch({
          type: "TABLE_DATA",
          payload: { [action.dest_table_key]: rows },
        });
      }
    } else if (action.actionName === "SELECTED_ROWS_ADD_TO_OTHER_TABLE") {
      if (tableInfo[`${action.parent_table_key}`]) {
        let selectedRowId = [];

        if (selectedRowInfo[`${action.parent_table_key}`]) {
          selectedRowId = selectedRowInfo[`${action.parent_table_key}`].map(
            (obj) => obj[`${action.uniqueKey}`]
          );
        }
        const insertedData = [];
        const selectedData = tableInfo[`${action.source}`];
        selectedData.filter((obj, index) => {
          if (selectedRowId.indexOf(obj[`${action.uniqueKey}`]) !== -1) {
            insertedData.push(obj);
          }
        });
        let requestData = {};
        requestData[`${action.destination_table_key}`] = [...insertedData];
        if (insertedData.length > 0) {
          dispatch({
            type: "TABLE_DATA",
            payload: requestData,
          });
        }
        if (action.path) {
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              action.path
          );
        }
      } else {
        // dispatch({
        //   type: "DISPLAY_TABLE",
        //   type: "DEPENDENT_COMPONENTS",
        //   payload: params,
        // });
      }
    } else if (action.actionName === "toggle") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action?.toggleKeysList) {
        action.toggleKeysList.forEach((toggleKey) => {
          if (dependentInfo.hasOwnProperty(`${toggleKey}`)) {
            // params[`${toggleKey}`] = dependentInfo[`${toggleKey}`] ? !dependentInfo[`${toggleKey}`] : true;
            params[`${toggleKey}`] = false;
          }
        });
      }
      if (
        action.otherKey &&
        dependentInfo.hasOwnProperty(`${action.otherKey}`)
      ) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      }
      if (action?.otherKeyEnableOnToggle) {
        action.otherKeyEnableOnToggle.forEach((toggleKey) => {
          if (dependentInfo.hasOwnProperty(`${toggleKey}`)) {
            params[`${toggleKey}`] =
              dependentInfo[`${action.key}`] && !dependentInfo[`${toggleKey}`]
                ? true
                : false;
          }
        });
      }

      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "ADD_REPEATED_COMPONENTS") {
      let params = {};
      let dependentKeys = Object.keys(dependentInfo).filter((keyName) =>
        keyName.includes(action.key)
      );
      params[`${action.key}_${dependentKeys.length + 1}`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (
      action.actionName === "TAB_REDIRECT_BASED_ON_DROPDOWNS_AND_BUTTON"
    ) {
      let path = action.pathConfig[0].path;
      action.pathConfig.forEach((conf) => {
        if (
          formEditedValues[action.pathDeciderField].length > 1 &&
          formEditedValues[action.pathDeciderField].join("And") === conf.trigger
        ) {
          path = conf.path;
        } else if (formEditedValues[action.pathDeciderField] === conf.trigger) {
          path = conf.path;
        }
      });
      if (path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            path
        );
      }
      if (
        action["isBasedOnDropdownKey"] &&
        formEditedValues[`${action.key}`] === action["isBasedOnDropdownKey"]
      ) {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
            isDependentOn: action.isDependentOn,
          },
        });
        setTimeout(() => {
          dispatch({
            type: "RESET_TAB_REDIRECT",
            payload: {},
          });
        }, 15000);
      }
    } else if (action.actionName === "UPDATE_ANOTHER_TABLE") {
      let inseasonReq = {};
      inseasonReq[`${action.parent_table_key}`] = [
        ...tableInfo[`${action.source_table_key}`],
      ];
      dispatch({
        type: "TABLE_DATA",
        payload: inseasonReq,
      });
    } else if (action.actionName === "DELETE_ROWS") {
      if (props?.otherInfo?.params) {
        invokeAction(action, params.data);
      }
    } else if (action.actionName === "CLICK_WITH_MESSAGE") {
      let params = {};

      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        // if(action.otherKey){
        params[`${action.otherKey}`] = true;
        // }
      }
      if (action.hasOwnProperty("defaultToggle")) {
        params[`${action.key}`] = action.defaultToggle;
      }
      setMessage(action.message);
      setShowMessage(true);
      setAction(action);
      setTimeout(() => {
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }, action.delay || 1500);
    } else if (action.actionName === "DUPLICATE_ROW") {
      const gridParams = props?.params;
      if (gridParams) {
        invokeAction(action, gridParams.data)
      }
    }
    else {
      if (!invokeAction(action))
        dispatch({
          type: "DISPLAY_TABLE",
          payload: action.key,
        });
    }
  };

  const onIconClickAction = (item, index) => {
    const { action } = item;
    const { parent_table_key, unique_key, add_values } = item.action;
    let params = {};
    if (item?.buttonAction) {
      onAction(action, index);
    } else if (action.actionName === "click") {
      if (editActionInfo[`${item.parent_table_key}`]) {
        params[`${item.parent_table_key}`] = null;
      } else {
        params[`${item.parent_table_key}`] = true;
      }
      dispatch({
        type: "EDIT_ACTION",
        payload: params,
      });
    } else if (action.actionName === "navigate") {
      navigate(
        "/" +
          window?.location?.pathname?.split("/")[1] +
          "/" +
          window?.location?.pathname?.split("/")[2] +
          action.path
      );
    } else if (action.actionName === "AddNewRowBasedOnEdit") {
      if (tableInfo[`${parent_table_key}`]) {
        let selectedRowId = null;

        if (selectedRowInfo[`${parent_table_key}`]) {
          selectedRowId = selectedRowInfo[`${parent_table_key}`].map(
            (obj) => obj[`${unique_key}`]
          );
        }
        const insertedData = [];
        const selectedData = selectedRowInfo[`${parent_table_key}`]
          ? selectedRowInfo[`${parent_table_key}`]
          : tableInfo[`${parent_table_key}`];
        selectedData.filter((obj, index) => {
          if (index === 0) {
            let params = {};
            Object.keys(obj).forEach((nestedObj) => {
              params[`${nestedObj}`] = null;
            });
            params[`${unique_key}`] = obj[`${unique_key}`];
            insertedData.push({ ...params, ...add_values });
          }
        });
        let requestData = {};
        requestData[`${parent_table_key}`] = [
          ...tableInfo[`${parent_table_key}`],
          ...insertedData,
        ];
        dispatch({
          type: "TABLE_DATA",
          payload: requestData,
        });
      }
    } else if (action.actionName === "modal") {
      setOpen(!open);
      setItemIndex(index);
    } else if (action.actionName === "toggle") {
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;

      if (
        action.otherKey &&
        dependentInfo.hasOwnProperty(`${action.otherKey}`)
      ) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else {
      invokeAction(action);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = (item, index) => {
    const { parent_table_key, iconType } = item;
    switch (iconType) {
      case "Arrow":
        return <KeyboardReturnIcon />;
      case "Add":
        return (
          <>
            {parent_table_key ? (
              <>
                {selectedRowInfo[`${parent_table_key}`] &&
                editActionInfo[`${parent_table_key}`] ? (
                  <Button
                    style={{ textTransform: "none", margin: 5 }}
                    variant={item.varient}
                    size={item.size}
                    title={item.name}
                    onClick={() => onIconClickAction(item, index)}
                  >
                    <AddIcon />
                  </Button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <AddIcon />
            )}
          </>
        );
        break;
      case "Edit":
        return (
          <>
            {parent_table_key ? (
              <>
                {selectedRowInfo[`${parent_table_key}`] &&
                editActionInfo[`${parent_table_key}`] ? (
                  <Button
                    style={{ textTransform: "none", margin: 5 }}
                    variant={item.varient}
                    size={item.size}
                    title={item.name}
                    onClick={() => {
                      if (item?.action && item?.callAction) {
                        if (Array.isArray(item.action)) {
                          item.action.forEach((action, index) =>
                            onAction(action, index)
                          );
                        } else {
                          onAction(item.action, index);
                        }
                      } else onIconClickAction(item, index);
                    }}
                  >
                    <UpdateIcon />
                  </Button>
                ) : (
                  <>
                    <Button
                      style={{ textTransform: "none", margin: 5 }}
                      variant={item.varient}
                      size={item.size}
                      title={item.name}
                      onClick={() => {
                        if (item?.action && item?.callAction) {
                          if (Array.isArray(item.action)) {
                            item.action.forEach((action, index) =>
                              onAction(action, index)
                            );
                          } else {
                            onAction(item.action, index);
                          }
                        } else onIconClickAction(item, index);
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                <EditIcon
                  onClick={() => {
                    if (item?.action) {
                      onAction(item.action, index);
                    }
                  }}
                />
              </>
            )}
          </>
        );
        break;
      case "Delete":
        return (
          <>
            {parent_table_key ? (
              <>
                {selectedRowInfo[`${parent_table_key}`] &&
                editActionInfo[`${parent_table_key}`] ? null : (
                  <>
                    <Button
                      style={{ textTransform: "none", margin: 5 }}
                      variant={item.varient}
                      size={item.size}
                      title={item.name}
                      onClick={() => onIconClickAction(item, index)}
                    >
                      <DeleteIcon />
                    </Button>
                  </>
                )}
              </>
            ) : (
              <DeleteIcon />
            )}
          </>
        );
        break;
      case "List":
        return <ListIcon />;
        break;
      case "Copy":
        return <ContentCopyIcon />;
      case "DeleteOutlined":
        return <DeleteOutlineIcon />;
      case "EditIcon":
        return (
          <EditIcon
            onClick={() => {
              if (Array.isArray(item.action)) {
                item.action.forEach((action, index) => onAction(action, index));
              } else {
                onAction(item.action, index);
              }
            }}
          />
        );
      case "Visible":
        return <VisibilityIcon />;
      case "Next":
        return <NavigateNextIcon />;
      case "Calendar":
        return <CalendarViewMonthIcon />;
      case "Analytics":
        return <AssessmentOutlinedIcon />;
      case "Compare":
        return <CompareIcon />;
      case "AddPlaylist":
        return <PlaylistAddIcon />;
      case "PostAdd":
        return <PostAddIcon />;
      case "Calculate":
        return <CalculateIcon />;
      case "AddNewRowBasedOnEdit":
        return (
          <>
            {editActionInfo[`${parent_table_key}`] || item.default_open ? (
              <>
                <Button
                  style={{ textTransform: "none", margin: 5 }}
                  variant={item.varient}
                  size={item.size}
                  title={item.name}
                  onClick={() => onIconClickAction(item, index)}
                >
                  <AddIcon />
                </Button>
              </>
            ) : null}
          </>
        );

        break;

      case "Update":
        return editActionInfo[`${parent_table_key}`] ? (
          <>
            <Button
              style={{ textTransform: "none", margin: 5 }}
              variant={item.varient}
              size={item.size}
              title={item.name}
              onClick={() => onIconClickAction(item, index)}
            >
              <UpdateIcon />
            </Button>
          </>
        ) : null;
        break;
      case "Download":
        return <DownloadIcon />;
      case "Upload":
        return <UploadIcon />;
      case "SettingIcon":
        return (
          <SettingsIcon
            onClick={() => {
              if (item?.action) {
                onAction(item.action, index);
              }
            }}
          />
        );
      case "List":
        return <FormatListBulletedIcon />;
      case "Token":
        return <TokenIcon />;
      case "dot":
        return <FiberManualRecordIcon style={item?.iconStyle} />;
      case "filter":
        return <FilterAltOutlinedIcon />;
      case "ExpandCircleDownIcon":
        return <ExpandCircleDownIcon />;
      case "chart":
        return <ShowChartIcon />;
      case "save":
        return <SaveIcon />;
      case "reload":
        return <CachedIcon />;
      case "returnIcon":
        return;
      case "remove":
        return <CloseIcon />;
      case "time":
        return <AccessTimeIcon />;
      case "queryStats":
        return <QueryStatsIcon />;
      case "Reset":
        return <RestartAltIcon />;
      case "Undo":
        return <UndoIcon />;
      case "MatchWith":
        return <JoinInnerIcon />;
      case "Excel":
        return <FontAwesomeIcon icon={faFileExcel} size="2x" />;
      case "BackIcon":
        return (
          <ArrowBackIcon
            onClick={() => {
              if (item?.action) {
                onAction(item.action, index);
              }
            }}
          />
        );
      default:
        return <AddIcon />;
        break;
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleToggle = (e, item) => {
    const { name, value } = e.target;
    switch (item.type) {
      case "UPDATE_CURRENT_ROW":
        if (tableInfo[`${item.parent_table_key}`]) {
          let selectedRowId = props.otherInfo.data[`${item.unique_key}`];

          const updatedData = tableInfo[`${item.parent_table_key}`].filter(
            (obj) => {
              if (obj[`${item.unique_key}`] == selectedRowId) {
                if (obj["include_unmapped_products"] === "TRUE") {
                  obj[`products_mapped`] = obj[`old_products_mapped`];
                } else {
                  obj[`old_products_mapped`] = obj[`products_mapped`];
                  obj[`products_mapped`] = "100/100";
                }
                obj["include_unmapped_products"] =
                  obj["include_unmapped_products"] === "TRUE"
                    ? "FALSE"
                    : "TRUE";
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.parent_table_key}`] = [...updatedData];
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }

        break;
      case "UPDATE_CURRENT_ROW_VALUE":
        if (
          tableInfo[`${item.parent_table_key}`] &&
          tableInfo[`${item.child_table_key}`]
        ) {
          let selectedRowId = props.otherInfo.data[`${item.unique_key}`];
          const childTable = tableInfo[`${item.child_table_key}`].filter(
            (obj) => {
              if (obj[`${item.unique_key}`] == selectedRowId) {
                obj["mapping"] = obj["mapping"] === "TRUE" ? "FALSE" : "TRUE";
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.child_table_key}`] = [...childTable];
          // dispatch({
          //   type: "TABLE_DATA",
          //   payload: params,
          //   });
        }
      default:
        break;
    }
  };

  function dynamicAction(action) {
    let payload = null;
    switch (action.payloadType) {
      case "row":
        payload = params.node;
        break;
      default:
        break;
    }
    invokeAction(action, payload);
  }
  const getActionType = (item, index) => {
    switch (props.otherInfo.actionType) {
      case "link":
        return (
          <a
            href="javascript:void(0)"
            onClick={() => onAction(item.action, index)}
            style={{ textDecoration: "none" }}
          >
            {props.otherInfo.isLabelAction}
          </a>
        );
        break;
      case "toggle":
        return (
          <>
            <Switch
              {...label}
              checked={props.otherInfo.isLabelAction === "TRUE"}
              onClick={(e) => {
                handleToggle(e, item);
              }}
            />
          </>
        );
        break;
      case "switch":
        return (
          <>
            {props.label}
            <FormControlLabel control={<Switch />} label={props.endLabel} />
          </>
        );
        break;
      case "text_and_icon_click":
        return (
          <>
            <span>
              {" "}
              <span>{props.otherInfo.isLabelAction} </span>&nbsp;&nbsp;{" "}
              <span>
                <a
                  href="javascript:void(0)"
                  onClick={() => onAction(item.action, index)}
                  style={{ textDecoration: "none", ...props.otherInfo.style }}
                >
                  {getIcon(props.otherInfo, 0)}
                </a>
              </span>
            </span>
          </>
        );
        break;
      default:
        return (
          <>
            {["Copy"].indexOf(props.otherInfo.isLabelAction) !== -1 ? (
              <>
                {props.data.details?.map((item, index) => (
                  <>
                    {/* {renderIcons(item)} */}
                    <EditIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        onAction(item.navigateAction, index);
                      }}
                    />{" "}
                    <ContentCopyIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        onAction(item.action, index);
                      }}
                    />{" "}
                    {item.action["isNoteIconNotRequired"] ? null : (
                      <>
                        {" "}
                        <DescriptionIcon sx={{ cursor: "pointer" }} />{" "}
                      </>
                    )}{" "}
                    {item.switchIconNeeded ? (
                      <UpdateIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          onAction(item.keyUpdateAction, index);
                        }}
                      />
                    ) : null}{" "}
                    {item.deleteIconNeeded ? (
                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          onAction(item.deleteAction, index);
                        }}
                      />
                    ) : null}{" "}
                  </>
                ))}
              </>
            ) : (
              <>
                {props.otherInfo.type === "Button" ? (
                  <Button
                    variant="contained"
                    sx={{ ...props.otherInfo.style }}
                    onClick={() => onAction(item.action, index)}
                    size="small"
                  >
                    {props.otherInfo.isLabelAction}
                  </Button>
                ) : (
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      let itemAction = item?.action;
                      if (
                        item?.cellActions &&
                        Number.isSafeInteger(cellActionIndex)
                      ) {
                        itemAction =
                          cellActionIndex < item.cellActions.length &&
                          item.cellActions[cellActionIndex];
                      }
                      if (itemAction) {
                        if (Array.isArray(itemAction)) {
                          itemAction.forEach((obj) => {
                            if (obj.dynamicPayload) {
                              dynamicAction(obj);
                            } else {
                              onAction(obj, index);
                            }
                          });
                        } else {
                          if (itemAction.dynamicPayload) {
                            dynamicAction(itemAction);
                          }
                          onAction(itemAction, index);
                        }
                      }
                    }}
                    style={
                      params &&
                      props.otherInfo?.data &&
                      props.otherInfo.data[
                        `#disable_${params.colDef.field}`
                      ] === "Y"
                        ? disableLinkStyle
                        : { textDecoration: "none" }
                    }
                  >
                    {props.otherInfo.isLabelAction}
                  </a>
                )}
              </>
            )}
          </>
        );
        break;
    }
  };

  const handleMessageClose = () => {
    setShowMessage(false);
  };
  useEffect(() => {
    if (data?.clearDependentsOnDestroy && data?.dependentOn) {
      return () => {
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: {
            [data.dependentOn]: false,
          },
        });
      };
    }
  }, []);
  const getModalData = (item) => {
    if (item?.cellActions) {
      return item.cellActions[cellActionIndex];
    }
    if (Array.isArray(item["action"]))
      return item["action"][item["action"].length - 1];
    return item["action"];
  };
  const handleIsDisabled = (item) => {
    const disableInfo = item?.disabledBy;
    // To control button disabling based on conditions
    switch (disableInfo?.condition) {
      // Disabled row only when rowSelection count is less than a threshold
      case "selectLessThan": {
        const rowData = selectedRowInfo?.[disableInfo?.TableKey];
        // In case rowSelection is done on grouped rows
        if (disableInfo?.rowGroupField) {
          const selectedRows = rowData.filter(
            (row, index, self) =>
              index ===
              self.findIndex(
                (property) =>
                  property?.[disableInfo?.rowGroupField] ===
                  row?.[disableInfo?.rowGroupField]
              )
          );
          return !(selectedRows?.length < disableInfo?.count);
        } else {
          // In case rowSelection is done on ungrouped/normal rows
          return !(rowData?.length < disableInfo?.count);
        }
      }
      default: {
        if (Array.isArray(item.disabledBy)) {
          return !item.disabledBy.some((str) => dependentInfo[str]);
        }
        return !dependentInfo[item.disabledBy];
      }
    }
  };
  const checkAnchorOrigin = () => {
    if (action) {
      return action?.anchorOrigin || { vertical: "bottom", horizontal: "left" };
    }
    return data.details[0]["action"]["anchorOrigin"];
  };
  return (
    <>
      {open ? (
        <CustomModal show={open} {...getModalData(data.details[itemIndex])} />
      ) : null}

      {showMessage && (
        <SnackBar
          message={message}
          variant={data.details[0]["action"]["variant"] || "success"}
          handleClose={handleMessageClose}
          anchorOrigin={checkAnchorOrigin()}
        />
      )}
      <Grid>
        <div style={{ textAlign: data && data.alignment, ...data.style }}>
          {data.details?.map((item, index) => (
            <>
              {props.otherInfo && props.otherInfo.isLabelAction ? (
                <>{getActionType(item, index)}</>
              ) : (
                <>
                  {item.isIcon ? (
                    <>
                      {item.parent_table_key ? (
                        <>
                          {selectedRowInfo[`${item.parent_table_key}`] &&
                            getIcon(item, index)}
                        </>
                      ) : (
                        <>
                          {getIcon(item, index) !== null ? (
                            <Button
                              // style={{
                              // 	textTransform: "none",
                              // 	margin: 5,
                              // 	...item.style,
                              // }}
                              style={{
                                textTransform: "none",
                                margin: 5,
                                width: item.width,
                                boxShadow: "none",
                                ...item?.style,
                              }}
                              sx={{
                                backgroundColor:
                                  item.varient === "contained"
                                    ? "#0055AF"
                                    : null,
                                ...item?.buttonStyle,
                              }}
                              variant={item.varient}
                              size={item.size}
                              title={item.name}
                              // disabled={obj.disableFeat && diable}
                              disabled={
                                item?.disabledBy
                                  ? !dependentInfo[`${item["disabledBy"]}`]
                                  : false
                              }
                              onClick={() => {
                                if (Array.isArray(item.action)) {
                                  item.action.forEach((action) =>
                                    onIconClickAction(
                                      { ...item, action },
                                      index
                                    )
                                  );
                                } else onIconClickAction(item, index);
                              }}
                            >
                              {getIcon(item, index)}
                              {item.iconLabel && (
                                <span style={{ marginLeft: "5px" }}>
                                  {item.iconLabel}
                                </span>
                              )}
                            </Button>
                          ) : null}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {item["isDisabledBasedOnRowSelection"] ||
                      item["isDisabledBasedOnOther"] ? (
                        <Button
                          style={{
                            textTransform: "none",
                            margin: 5,
                            ...item.style,
                          }}
                          variant={item.varient}
                          size={item.size}
                          title={item.name}
                          // disabled={obj.disableFeat && diable}
                          onClick={() => {
                            if (Array.isArray(item.action)) {
                              item.action.forEach((action) =>
                                onAction(action, index)
                              );
                            } else {
                              onAction(item.action, index);
                            }
                          }}
                          disabled={
                            (item.isDisabledBasedOnRowSelection
                              ? selectedRowInfo[
                                  `${item.isDisabledBasedOnRowSelection}`
                                ] &&
                                selectedRowInfo[
                                  `${item.isDisabledBasedOnRowSelection}`
                                ].length > (item.selectedRowscount || 0)
                                ? false
                                : true
                              : false) ||
                            (item.isDisabledBasedOnOther &&
                              !dependentInfo[`${item.isDisabledBasedOnOther}`])
                          }
                        >
                          {/* {item.name} */}
                          {item?.iconName
                            ? getIcon(
                                { ...item, iconType: item.iconName },
                                index
                              )
                            : item.name}
                        </Button>
                      ) : (
                        <>
                          {item.type === "download" ? (
                            <DownloadFiles {...item} />
                          ) : (
                            <>
                              {item[`dependentOn`] ? (
                                <>
                                  {dependentInfo[`${item[`dependentOn`]}`] ? (
                                    <>
                                      {item["type"] === "buttonDropdown" ? (
                                        <ButtonDropdown
                                          data={item}
                                          isDivNotRequired={true}
                                        />
                                      ) : (
                                        <Button
                                          style={{
                                            textTransform: "none",
                                            margin: 5,
                                            width: item.width,
                                            backgroundColor:
                                              item.varient === "contained"
                                                ? "#0055AF"
                                                : null,
                                            boxShadow: "none",
                                          }}
                                          disabled={
                                            item?.disabledBy
                                              ? !dependentInfo[
                                                  `${item["disabledBy"]}`
                                                ]
                                              : false
                                          }
                                          variant={item.varient}
                                          size={item.size}
                                          title={item.name}
                                          // disabled={obj.disableFeat && diable}
                                          onClick={() => {
                                            if (Array.isArray(item.action)) {
                                              item.action.forEach((action) =>
                                                onAction(action, index)
                                              );
                                            } else onAction(item.action, index);
                                          }}
                                        >
                                          {item.iconType
                                            ? getIcon(item, index)
                                            : null}{" "}
                                          {item.name}
                                        </Button>
                                      )}
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <Button
                                  style={{
                                    textTransform: "none",
                                    margin: 5,
                                    width: item.width,
                                    boxShadow: "none",
                                    ...item?.style,
                                  }}
                                  sx={{
                                    backgroundColor:
                                      item.varient === "contained"
                                        ? "#0055AF"
                                        : null,
                                    ...item?.buttonStyle,
                                  }}
                                  disabled={
                                    item?.disabledBy ? handleIsDisabled(item) : false
                                  }
                                  variant={item.varient}
                                  size={item.size}
                                  title={item.name}
                                  // disabled={obj.disableFeat && diable}
                                  onClick={() => {
                                    if (Array.isArray(item.action)) {
                                      item.action.forEach((action) => {
                                        onAction(action, index);
                                      });
                                    } else onAction(item.action, index);
                                  }}
                                >
                                  {item.iconType ? getIcon(item, index) : null}{" "}
                                  <Text style={item?.style}>{item.name}</Text>
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ))}
        </div>
      </Grid>
    </>
  );
};

export default CustomActionButton;

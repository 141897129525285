import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";

const CellTextField = (props) => {
  const { tableValues,tableInfo } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const CellValueHandler = (newValue) => {
    if(props.mappingKey){
      let payload = {};
      let tableData = tableInfo[props.mappingKey];
      tableData[props.rowIndex][props.column.colId] = newValue;
      payload[props.mappingKey] = tableData;
      // dispatch({
      //   type: "TABLE_VALUES",
      //   payload: payload,
      // });
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (props.updateOnEdit) {
      props.node.setDataValue(props.colDef.field,newValue)
    }
    
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextFieldWithLabel
        type="text"
        defaultValue={props.value}
        disabled={props.disabled}
        onChange={(e) => CellValueHandler(e.target.value)}
      />
    </div>
  );
};

export default CellTextField;

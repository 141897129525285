import ReactMuiDatePicker from "./ReactMuiDatePicker";
import moment from "moment";

export default function CellDateRange(params) {
  const val = getDefaultValue(params);
  function renderDates(str) {
    return str.split(",").map((value) => {
      return <ReactMuiDatePicker value={value} />;
    });
  }
  return val ? (
    <div style={{ display: "flex", gap: "10px" }}>
      {renderDates(val)}
    </div>
  ) : null;
}
function getDefaultValue(params) {
  if (params.value) {
    return params.value
  }
  let currentDate = new Date()
  const { offset } = params;
    if (offset) {
      switch (offset.type) {
        case "months":
          {
            currentDate.setMonth(currentDate.getMonth() + offset.value);
          }
          break;
        default: {
          currentDate.setDate(currentDate.getDate() + offset.value);
        }
      }
    }
  return params.defaultDate ? moment(currentDate).format('YYYY-MM-DD') : ""
}
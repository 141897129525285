import React, { useState } from "react";
import moment from "moment";
// Impact UI component
import "./style.css"
import { DateRangePicker } from "impact-ui";

const CustomDateRangePicker = (props) => {
  const { label, values, isMandatory } = props;
  var date = moment();

  var currentDate = date.format("YYYY/MM/DD");
  const [startDate, setStartDate] = useState(
    moment(new Date(values?.[0] || currentDate))
  );
  const [endDate, setEndDate] = useState(
    moment(new Date(values?.[1] || currentDate))
  );

  return (
    <DateRangePicker
      label={
        <>
          {label}{" "}
          {isMandatory ? <span style={{ color: "red" }}> * </span> : null}
        </>
      }
      numberOfMonths={2}
      showRangeSelector
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      startDateInputProps={{ label: "StartDate", name: "startDate" }}
      endDateInputProps={{ label: "EndDate", name: "endDate" }}
    />
  );
};

export default CustomDateRangePicker;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormLabel, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { fetchFormDataAction } from "../action";
import CustomCard from "../../../components/CustomCard";
import HorizontalTables from "../CustomComponents/horizontalTables";
import VerticleTables from "../CustomComponents/verticleTables";
import HorizontalCharts from "../CustomComponents/horizontalCharts";
import VerticalCharts from "../CustomComponents/verticalCharts";
import CustomisedCard from "../CustomComponents/customisedCard";
import Steppers from "../../../components/Steppers/Steppers";
import Tabs from "./TabResolver";
import Map from "../../../components/Map";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import CustomActionButton from "../CustomComponents/CustomActionButton";
import FiltersList from "../FiltersList";
import Calendar from "../../../components/Calendar";
import KpiCardComponent from "../CustomComponents/KpiCardComponent";
import CustomToggle from "../CustomComponents/CustomToggle";
import ActionKPICard from "../CustomComponents/ActionKPICard";
import Container from "../CustomComponents/Container";
import FormsList from "../DynamicForms/filtersForms";
import SelectRenderer from "../../../components/Select/SelectRenderer";
import { CustomModal } from "../CustomComponents/customModal";
import ActionComponents from "../CustomComponents/actionComponents";
import { StrategyConfig } from "../strategyConfig";
import { OptimizationConstraints } from "../optimizationConstraints";
import ButtonDropdown from "../CustomComponents/ButtonDropdown";
import CustomRadioButtons from "../CustomComponents/CustomRadioButtons";
import CustomTable from "../CustomComponents/CustomTable";
import InfoPanel from "../../../components/InfoPanel";
import StepFourImg from "../../../assets/images/Img.png";
import SplitTable from "../../Home/SplitTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomDateRange from "../CustomComponents/CustomDateRange";
import DownloadFiles from "../CustomComponents/DownloadFiles";
import CheckboxGroup from "../../../components/CheckboxGroup";
import TextArea from "../../../components/TextArea";
import CustomAccordion from "../../../pages/Home/CustomComponents/CustomAccordian";
import VerticalTabs from "../CustomComponents/VerticalTabs";
import XDView from "../CustomComponents/XDView";
import FigmaView from "../CustomComponents/FigmaView";
import "../../MainCss.scss";
import CircularProgressBar from "../CustomComponents/CircularProgressBar";
import DataLabel from "../CustomComponents/DataLabel";
import ReportsMap from "../../../components/ReportsMap";
import Treechart from "../../../components/Charts/formatter/TreeChart";
import Filters from "../../../components/FilterChips";
import DataChart from "../CustomComponents/DataChart";
import SimulationResultCards from "../CustomComponents/SimulationResultCards";
import CustomImage from "../CustomComponents/CustomImage";
import CustomIncremntDecremnt from "../CustomComponents/CustomIncremntDecremnt";
import CustomCounterButton from "../CustomComponents/CustomCounterButton";
import SearchBar from "../CustomComponents/SearchBar";
import ButtonWithIcon from "../CustomComponents/ButtonWithIcon";
import CustomCartersKPICard from "../CustomComponents/CustomCartersKPICard";
import CustomSlider from "../CustomComponents/CustomSlider";
import DragRect from "../../../components/reactDraggable/draggable";
import SplitTableGeneric from "../../Home/CustomComponents/SplitTableGeneric";
import FileSelectors from "../../../components/FileSelectors/index";
import ColorScale from "../CustomComponents/ColorScale";
import CustomMap from "../../../components/CustomMap/CustomMap";
import TimeLine from "../CustomComponents/Timeline";
import ImageDisplay from "../CustomComponents/ImageDisplay";
import VisualDisplay from "../CustomComponents/VisualDisplay";
import ColorKPICardComponent from "../CustomComponents/ColorKpiCard";
import CustomTestSmartCard from "../CustomComponents/CustomTestSmartCard";
import CustomButton from "../../../components/CustomButton";
import NewCalendar from "../../../components/CalendarNew/Calendar";
import CustomDatePicker from "../../../components/CustomFormDatePicker/index";

import DateTimePicker from "../../../components/CustomDateTimePicker";
import { GetDetailCartersContent } from "../../../components/CalendarNew/Components/GetDetailCartersContent";
import { GetDetailContent } from "../../../components/CalendarNew/Components/GetDetailContent";
import {
  eventStatusColors,
  eventStatusColorsCarters,
  sampleFiscalCalendarData,
  toggleOptions,
} from "../../../components/CalendarNew/Constants";
import CalendarEventsColorInfo from "../../../components/CalendarNew/Components/CalendarEventsColorInfo";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import SelectedRowText from "../CustomComponents/SelectedRowText";
import CellChips from "../CustomComponents/CellChips";
import ForecastKpiCard from "../../../components/ForecastKpiCard";
import SnippetBoard from "../CustomComponents/SnippetBoard";
import List from "../CustomComponents/Lists";
import TableColumnsShowHidePopOver from "../CustomComponents/TableColumnsShowHidePopOver";
import ArcherField from "../CustomComponents/Archer";
import TemporalProvider from "../CustomComponents/TemporalProvider";
import FileUploader from "../../../components/FileUploader";
import Chatbot from "../../../components/Chatbot";
import CardsA from "../CustomComponents/DynamicKpiCards/CardsA";
import ErrorBoundary from "../../../utils/errorBoundary";
import ExpandableKpiCard from "../CustomComponents/ExpandableKpiCard";
const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    overflow: theme.content.overflow.visible,
    boxShadow: "none",
    background: "none",
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
  filtersHeader: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    float: "right",
    text: "right",
  },
  filtersHeaderTitle: {
    ...theme.typography.fontSizes.sectionHeaderText,
    margin: "0",
    marginRight: 48,
    fontWeight: theme.typography.fontWeight.bold,
  },
});

const useStyles = makeStyles(styles);

const ComponentResolver = (props) => {
  const { pageObject } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const {
    apiData,
    dependentInfo,
    tableInfo,
    selectedRowInfo,
    selectedDropdownValues,
    columnsInfo,
  } = useSelector((state) => state.home);
  const { formMasterValuesData, formMenuData } = useSelector(
    (state) => state.filter
  );

  const [open, setOpen] = useState(false);
  const [componentsList, setComponentsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedItems, setSelectedItems] = useState({});

  useEffect(() => {
    if (formMasterValuesData.length === 0) {
      dispatch(
        fetchFormDataAction({
          model_names: ["tb_form_master", "tb_form_master_values"],
        })
      );
    }
  }, []);

  useEffect(() => {
    if (pageObject["enable_default_key"]) {
      let params = {};
      params[`default-key`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
    if (pageObject["components"]) {
      setComponentsList([...pageObject.components]);
    }
  }, [pageObject]);
  useEffect(() => {
    if (pageObject["defaultEnabledKeys"]) {
      let params = {};
      pageObject["defaultEnabledKeys"].forEach((item) => {
        params = {
          ...params,
          [item]: true,
        };
      });
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
  }, []);
  const reloadFilters = async () => {
    setSelectedFilters({});
  };

  const openLoader = () => {
    setLoader(true);
  };

  const closeLoader = (metric) => {
    setLoader(false);
  };
  const updateState = (params, data) => {
    setSelectedFilters(params);
    if (data.action) {
      switch (data.action.type) {
        case "UPDATE_TABLE_DATA_BASED_ON_SELECTION":
          let tableParams = {};
          tableParams[`${data.action.source_table_key}`] =
            tableInfo[`${data.action.parent_table_key}`];
          dispatch({
            type: "TABLE_DATA",
            payload: tableParams,
          });
          break;

        default:
          break;
      }
    }
  };
  const appliedFiltersAction = (params, data) => {
    if (data.action && data.action.type === "UPDATE_MASTER_PLAN") {
      let tableParams = {};
      tableParams[`${data.action.source}`] = tableInfo[`${data.action.key}`];
      if (selectedFilters.hasOwnProperty("Plan Version")) {
        const planData = selectedFilters["Plan Version"][0]["label"];
        if (planData === "Working Plan") {
          let reqParams = {};
          reqParams[`${data.filter_id}`] = true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: reqParams,
          });
          // dispatch({
          // 	type: "TABLE_DATA",
          // 	payload: tableParams,
          // });
        } else {
          tableParams[`${data.action.source}`] =
            tableInfo[`${data.action.originalPlan}`];
          // dispatch({
          // 	type: "TABLE_DATA",
          // 	payload: tableParams,
          // });
        }
      }
      if (selectedFilters.hasOwnProperty("Channel")) {
        const channelData = selectedFilters["Channel"]
          .map((obj) => obj.label)
          .join("_");
        if (
          data.action.basedOnOptions &&
          data.action.basedOnOptions[`${channelData}`]
        ) {
          tableParams[`${data.action.source}`] =
            tableInfo[`${data.action.basedOnOptions[`${channelData}`]}`];
        }
      }
      if (selectedFilters.hasOwnProperty("Vendors")) {
        const channelData = selectedFilters["Vendors"]
          .map((obj) => obj.label)
          .join("_");
        if (
          data.action.basedOnOptions &&
          data.action.basedOnOptions[`${channelData}`]
        ) {
          tableParams[`${data.action.source}`] =
            tableInfo[`${data.action.basedOnOptions[`${channelData}`]}`];
        }
      }
      dispatch({
        type: "TABLE_DATA",
        payload: tableParams,
      });
    } else if (
      data.action &&
      data.action.type === "FILTER_DATA_BASED_ON_SELECTION" &&
      data.action.filterKeys
    ) {
      let tableData = tableInfo[`${data.action.source}`];
      data.action.filterKeys.forEach((obj) => {
        let category = "",
          field = "";
        if (typeof obj === "string") {
          category = obj;
          field = obj;
        } else {
          category = obj.category;
          field = obj.field;
        }
        let dropdownValues =
          params[`${category}`] && params[`${category}`].length > 0
            ? params[`${category}`].map((valueObj) => valueObj.value)
            : [];
        if (dropdownValues && dropdownValues.length > 0) {
          tableData = tableData.filter((filterObj) => {
            return dropdownValues.indexOf(filterObj[`${field}`]) !== -1;
          });
        }
      });
      let tableParams = {};
      tableParams[`${data.action.destination}`] = tableData;
      dispatch({
        type: "TABLE_DATA",
        payload: tableParams,
      });
    } else if (data?.action && data?.action?.type === "APPLY_FILTER") {
      let dependentParams = {};
      dependentParams[`${data.action.dependentKey}`] =
        !dependentInfo[`${data.action.dependentKey}`];
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: dependentParams,
      });
    }
  };

  const onReset = () => {
    reloadFilters();
  };

  const onLabelActionClick = (data) => {
    switch (data.action_type) {
      case "modal":
        setOpen(!open);

      case "click":
        let params = {};
        params[`${data.name}`] = dependentInfo[`${data.name}`]
          ? !dependentInfo[`${data.name}`]
          : true;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      case "RECALCULATE_IA_FORECAST":

      // setModalInfo(data)
      default:
        break;
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelect = (params, obj) => {
    switch (obj.actionType) {
      case "AddNewRow":
        const insertedData = [];
        const isDataExisted = tableInfo[`${obj.parent_table_key}`].map(
          (tablObj) => tablObj[`${obj.unique_key}`]
        );
        if (!isDataExisted.includes(params.selectedItems[0]["value"])) {
          tableInfo[`${obj.parent_table_key}`].filter((tableObj, index) => {
            if (index === 0) {
              let reqParams = {};
              Object.keys(tableObj).forEach((nestedObj) => {
                reqParams[`${nestedObj}`] = 0;
              });
              reqParams[`${obj.unique_key}`] = params.selectedItems[0]["value"];
              insertedData.push({ ...reqParams });
            }
          });
          let requestData = {};
          requestData[`${obj.parent_table_key}`] = [
            ...tableInfo[`${obj.parent_table_key}`],
            ...insertedData,
          ];
          dispatch({
            type: "TABLE_DATA",
            payload: requestData,
          });
        }

        break;
      case "DROPDOWN_EDIT":
        let formEditParams = {};
        formEditParams[`${obj.form_label}`] = params.selectedItems;
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: formEditParams,
        });
        break;
      case "OPTION_SELECT":
        let selectionData = {};
        selectionData[obj.dropdown_id] = params.selectedItems;
        dispatch({
          type: "GET_DROPDOWN_SELECTION_DATA",
          payload: selectionData,
        });
        break;
      default:
        break;
    }
    let updatedValues = { ...selectedItems };
    updatedValues[`${obj.componentKey || obj.form_label}`] =
      params.selectedItems;
    setSelectedItems(updatedValues);
  };
  const onButtonClick = (data) => {
    if (data["button_click"]) {
      let params = {};
      params[`${data.button_name}`] = dependentInfo[`${data.button_name}`]
        ? !dependentInfo[`${data.button_name}`]
        : true;
      params[`${data.button_toggle_other_key}`] = params[`${data.button_name}`]
        ? false
        : true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
  };

  const handleCheckboxChange = (e, obj, index) => {
    const { name, value } = e.target;
    if (obj["isStoreRequired"]) {
      let params = {};
      params[`${name}`] = dependentInfo[`${name}`]
        ? !dependentInfo[`${name}`]
        : true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else {
      setSelectedOption({
        index: index,
        dependentOn: name,
      });
    }
  };

  const onAction = (reqObj) => {
    const { key, actionName } = reqObj;
    if (actionName) {
      switch (actionName) {
        case "DEPENDENT":
          let params = {};
          params[`${key}`] = dependentInfo[`${key}`]
            ? !dependentInfo[`${key}`]
            : true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
          break;

        default:
          break;
      }
    }
  };

  const renderView = (propsObj) => {
    const { data, type, key } = propsObj;
    // console.log(propsObj, "here is it:::");
    switch (type) {
      case "stepper":
        return <Steppers data={data} />;
      case "textField":
        return (
          <>
            {data.info.map((field) => (
              <TextFieldWithLabel
                {...field}
                type={field.type}
                label={field.label}
                width={field.width}
                defaultValue={field.defaultValue}
                {...field}
              />
            ))}
          </>
        );
      case "map":
        return <Map info={data} />;
      case "customMap":
        return <CustomMap info={data} />;
      case "timeline":
        return <TimeLine info={data} />;
      case "search":
        return <SearchBar data={data} />;
      case "date-range-picker":
        return <CustomDateRange data={data} />;
      case "radio":
        return <CustomRadioButtons data={data} />;

      case "KPICard":
        return <KpiCardComponent data={data} />;

      case "ActionKPICard":
        return <ActionKPICard data={data} />;
      case "ColorKPICard":
        return <ColorKPICardComponent data={data} />;

      case "TestSmartCard":
        return <CustomTestSmartCard data={data} />;

      case "buttonWithIcon":
        return <ButtonWithIcon data={data} />;

      case "counter":
        return <CustomIncremntDecremnt data={data} />;

      case "draggable":
        return <DragRect data={data} />;

      case "card":
        return (
          <Typography gutterBottom variant="h5">
            {data?.title}
            {data.info?.map((item) => (
              <CustomisedCard item={item} />
            ))}
          </Typography>
        );
      case "horizontalCharts":
        return <HorizontalCharts charts={data.info} {...data} />;
      case "verticalCharts":
        return <VerticalCharts charts={data.info} />;
      case "FileSelectors":
        return <FileSelectors props={propsObj.data} />;
      case "horizontalTables":
        return <HorizontalTables tables={data.info} />;
      case "table":
        return (
          <ErrorBoundary fallback={<div></div>}>
          <VerticleTables
            tables={data.info}
            createExtraColDefs={data.createExtraColDefs}
          />
          </ErrorBoundary>
        );
      case "bottomActionButton":
        return (
          <div style={data.style}>
            <CustomActionButton data={data} />
          </div>
        );
      case "buttonDropdown":
        return <ButtonDropdown data={data} />;
      case "colorScale":
        return <ColorScale data={data} />;
      case "dataChart":
        return <DataChart data={data} />;
      case "simulationResultCard":
        return <SimulationResultCards data={data} />;
      case "tabs":
        return (
          <Tabs
            pageObject={{
              tabs: data.info,
              noReset: data.noReset,
              components: data.components,
              redirectionOnClick: data.redirectionOnClick,
              initialSelectedIndex: data.initialSelectedIndex,
              ...data,
            }}
          />
        );
      case "calendar":
        return <Calendar onCalEventClick={null} dataObj={data} />;
      case "newCalendar":
        return (
          <>
            <NewCalendar
              onCalEventClick={null}
              dataObj={data}
              isLoading={false}
              fiscalCalendar={sampleFiscalCalendarData}
              eventList={data.eventList}
              calendarFrequency={toggleOptions && toggleOptions[1]?.value}
              calMinDate={moment("10/05/2023")}
              calMaxDate={moment("11/29/2023")}
              getDetailContent={
                data.for == "carters"
                  ? GetDetailCartersContent
                  : GetDetailContent
              }
            />
            <CalendarEventsColorInfo
              eventStatusColors={
                data.for == "carters"
                  ? eventStatusColorsCarters
                  : eventStatusColors
              }
            />
          </>
        );
      case "hr":
        return <hr />;
      case "filters":
        return (
          <div className={"impact-filterList impact-filterList" + key + type}>
            <FiltersList
              hideButtons={data.hide_buttons}
              expand={data.expand}
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={(params) => {
                updateState(params, data);
              }}
              appliedFilters={(params) => {
                appliedFiltersAction(params, data);
              }}
              onReset={(params) => {
                onReset(params, data);
              }}
              screenName={"Filters"}
              isFilterNotRequired={{}}
              filter_id={data.filter_id}
              filter_label={data.filter_label}
              isFilterButtonNotRequired={data.isFilterButtonNotRequired}
              horizontalView={data.horizontalView}
              {...data}
            />
          </div>
        );
      case "forms":
        return (
          <>
            <FormsList
              updateParentState={(params) => {
                updateState(params, data);
              }}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              isSubmitRequired={data.isSubmitRequired}
              form_label={data.form_label}
              isTopFilters={data.isTopFilters}
              filter_id={data.filter_id}
              onLabelActionClick={() => {
                onLabelActionClick(data);
              }}
              onButtonClick={() => {
                onButtonClick(data);
              }}
              submitButtonName={data.submitButtonName}
              resetName={data.resetName}
              horizontalView={data.horizontalView}
            />
            {open && data.index === key ? (
              <>
                <CustomModal show={open} {...data} handleClose={handleClose} />
              </>
            ) : null}
          </>
        );
      case "select":
        const optionsList = formMasterValuesData.filter((obj) => {
          if (parseInt(obj.parent_id) === data.filter_id) {
            obj.label = obj.name;
            obj.value = obj.name;
            return obj;
          }
        });

        const formDetails =
          formMenuData.filter(
            (obj) => parseInt(obj["categoryid"]) === data.filter_id
          )[0] || {};
        const defaultValue = formMasterValuesData.filter((obj) => {
          if (data.defaultValue) {
            if (data.defaultValue.includes(obj.id)) {
              obj.label = obj.name;
              obj.value = obj.name;
              return obj;
            }
          } else if (parseInt(obj.parent_id) === data.filter_id) {
            if (obj.is_default_value === "Y") {
              obj.label = obj.name;
              obj.value = obj.name;
              return obj;
            }
          }
        });
        return (
          <>
            <Grid item md={data.grid || 3}>
              <SelectRenderer
                options={optionsList}
                isMandatory={data.isMandatory}
                isMulti={
                  data.isFormFilters
                    ? formDetails.type === "single-select"
                      ? false
                      : true
                    : false
                }
                width={data.width || ""}
                filterLabel={
                  data.isFormFilters ? formDetails.label : data.form_label
                }
                updateSelected={(params) => {
                  handleSelect(params, data);
                }}
                selectedItems={
                  selectedItems[`${data.componentKey || data.form_label}`] &&
                  selectedItems[`${data.componentKey || data.form_label}`]
                    .length > 0
                    ? selectedItems[`${data.componentKey || data.form_label}`]
                    : defaultValue
                }
                {...data}
              />
            </Grid>
          </>
        );
      case "spacer":
        return <div className="content-spacer" style={data.style}></div>;
      case "snippetBoard":
        return <SnippetBoard data={data} />;
      case "list":
        return <List data={data} />;
      case "slider":
        return <CustomSlider data={data} />;
      case "modal":
        // return <CustomModal show={open} data={data} />;
        return (
          <CustomModal show={true} {...data.action} handleClose={handleClose} />
        );
      case "actionComponents":
        return <ActionComponents data={data} />;
      case "container":
        return <Container data={data} />;
      case "toggler":
        return <CustomToggle data={data} />;
      case "counterButton":
        return <CustomCounterButton data={data} />;
      case "label":
        return (
          <div style={data.style}>
            <label>{data.name} </label>
          </div>
        );

      case "strategry-component":
        return (
          <>
            <StrategyConfig data={data} />
          </>
        );
      case "optimization-constraints":
        return (
          <>
            <OptimizationConstraints />
          </>
        );
      case "horizontal-labels":
        return (
          <>
            <Grid container>
              <Grid item xs={3} sm={3} md={3}>
                {data.label ? (
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    {data.label}
                  </FormLabel>
                ) : null}
              </Grid>
              {data.options?.map((option) => (
                <Grid item xs>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    {option.label}
                  </FormLabel>{" "}
                  &nbsp;&nbsp;
                  <FormLabel style={{ color: "black" }}>
                    {selectedRowInfo[`${data.parent_table_key}`] &&
                      selectedRowInfo[`${data.parent_table_key}`][0][
                        `${option.mappingKey}`
                      ]}
                  </FormLabel>
                </Grid>
              ))}
            </Grid>
          </>
        );
      case "image":
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <img width="100%" src={StepFourImg} alt="photo" />
          </div>
        );
      case "customImage":
        return <CustomImage data={data} />;
      case "info":
        return <InfoPanel data={data} />;
      case "split-table":
        return <SplitTable tables={data.info} />;

      case "toggle":
        return (
          <FormControlLabel
            control={
              <Switch
                defaultChecked={data?.defaultChecked === false  ? false  : true}
                disabled={data?.disabled || false}
              />
            }
            label={data.label}
          />
        );
      case "download":
        return <DownloadFiles {...data} />;
      case "Checkboxes":
        return (
          <>
            <CheckboxGroup
              label={data.label}
              options={data.options}
              row={!data.column}
              values={1}
              handleChange={(e) => handleCheckboxChange(e, data, 0)}
            />
          </>
        );
      case "customTable":
        return <CustomTable />;
      case "textarea":
        return <TextArea data={data} />;
      case "accordian":
        return <CustomAccordion data={data} />;
      case "verticalTabs":
        return <VerticalTabs data={data} />;
      case "circularProgressBar":
        return <CircularProgressBar data={data} />;
      case "dataLabel":
        return <DataLabel data={data} onAction={onAction} />;
      case "fileSelector":
        return <FileSelectors data={data} />;
      case "reportsMap":
        return <ReportsMap info={data} />;
      case "treechart":
        return <Treechart charts={data} />;
      case "cellChips":
        return <CellChips value={data.value} />;
      case "FilterChips":
        return (
          <>
            {" "}
            <Filters
              filtersSummary={data.data}
              selectedDropdownValues={selectedDropdownValues}
            />{" "}
          </>
        );
      case "data-definitions":
        return (
          <>
            {data.data.map((obj) => (
              <>
                <div style={obj.style}>
                  {obj.label ? <>{obj.label} : &nbsp;&nbsp;</> : null}
                  <label>{obj.name} </label>
                </div>
              </>
            ))}
          </>
        );
      case "grid-view-labels":
        return (
          <>
            <Grid container spacing={1} style={data.style}>
              {data.data.map((obj) => (
                <>
                  {obj.size ? (
                    <>
                      <Grid item md={obj.size} xl={obj.size} lg={obj.size}>
                        <Typography style={obj.labelStyle}>
                          {obj.label}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs>
                        <Typography style={obj.labelStyle}>
                          {obj.label}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              ))}
            </Grid>
          </>
        );
      case "CartersKPICard":
        return <CustomCartersKPICard data={data} />;
      case "split-table-generic":
        return <SplitTableGeneric data={data.tables} />;
      case "imageDisplay":
        return <ImageDisplay data={data} />;
      case "visualDisplay":
        return <VisualDisplay data={data} />;
      case "xdView":
        return <XDView data={data} />;
      case "figmaView":
        return <FigmaView data={data} />;
      case "SelectedRowText":
        return <SelectedRowText data={data} />;
      case "datePicker":
        return <CustomDatePicker data={data} onChange={() => {}} />;
      case "dateTime":
        return <DateTimePicker data={data} />;
      case "forecastKPI":
        return <ForecastKpiCard data={data} />;
      case "showHideTableColumns":
        return <TableColumnsShowHidePopOver data={data} />;
      case "archerField":
        return <ArcherField data={data} />;
      case "temporalProvider":
        return <TemporalProvider data={data} />;
      case "fileUpload":
        return <FileUploader data={data} />;
      case "chatbot":
        return <Chatbot data={data} />;
      case "dynamicCardsA":
        return <CardsA data={data} />;
      case "expandableKPICard":
        return <ExpandableKpiCard data={data} />;
      default:
        return "";
    }
  };
  const getMultipleDependence = (obj, dependentInfo) => {
    switch (obj?.dependencyType) {
      case "some": {
        return obj.dependentOn.some((key) => dependentInfo[key] == true);
      }
      default: {
        return obj.dependentOn.every((key) => dependentInfo[key] == true);
      }
    }
  };
  return props?.spread ? (
    <>
      {pageObject.components &&
        componentsList.map((item, index) => {
          let dependence = true;
          const dependentOn = item["dependentOn"];
          if (dependentOn) {
            dependence = Array.isArray(dependentOn)
              ? getMultipleDependence(item, dependentInfo)
              : dependentInfo[dependentOn];
            dependence = item["invertDependence"] ? !dependence : dependence;
          }
          return item.type !== "container" ? (
            <div
              key={item.type}
              className={
                "impact-container-resolver  impact-subContainer-resolver" +
                item.type
              }
              style={item?.wrapperStyle}
            >
              {item.hasOwnProperty("flexComponent") && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: item.flexComponent.flex,
                  }}
                  className={"impact-flexComponent-" + item.type}
                >
                  {item.flexComponent.components.map((thisComp, index) => {
                    return (
                      <>
                        <div>
                          {renderView({
                            key: index,
                            type: thisComp.type,
                            data: thisComp,
                          })}
                        </div>
                        <div style={{ marginRight: "1rem" }} />
                      </>
                    );
                  })}
                </div>
              )}
              {dependence ? (
                <>
                  {item["rootDependentName"] ? (
                    <>
                      {dependentInfo[`${item.rootDependentName}`] ? (
                        <>
                          {renderView({
                            key: index,
                            type: item.type,
                            data: item,
                          })}
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {renderView({
                        key: index,
                        type: item.type,
                        data: item,
                      })}
                    </>
                  )}
                </>
              ) : null}

              {/* <div className="content-spacer"></div> */}
            </div>
          ) : (
            <>
              {item.hasOwnProperty("flexComponent") && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: item.flexComponent.flex,
                  }}
                  className={"impact-flexComponent-" + item.type}
                >
                  {item.flexComponent.components.map((thisComp, index) => {
                    return (
                      <>
                        <div>
                          {renderView({
                            key: index,
                            type: thisComp.type,
                            data: thisComp,
                            // width: thisComp.width
                          })}
                        </div>
                        <div style={{ marginRight: "1rem" }} />
                      </>
                    );
                  })}
                </div>
              )}
              {dependence ? (
                <>
                  {item["rootDependentName"] ? (
                    <>
                      {dependentInfo[`${item.rootDependentName}`] ? (
                        <>
                          {renderView({
                            key: index,
                            type: item.type,
                            data: item,
                          })}
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {renderView({
                        key: index,
                        type: item.type,
                        data: item,
                      })}
                    </>
                  )}
                </>
              ) : null}
            </>
          );
        })}
    </>
  ) : (
    <section className="impact-component-resolver">
      {Object.keys(pageObject).length > 0 && pageObject["pageTitle"] ? (
        <h3 style={pageObject?.pageTitleStyle || {}}>{pageObject.pageTitle}</h3>
      ) : null}
      {pageObject.components &&
        componentsList.map((item, index) => {
          let dependence = true;
          const dependentOn = item["dependentOn"];
          if (dependentOn) {
            dependence = Array.isArray(dependentOn)
              ? getMultipleDependence(item, dependentInfo)
              : dependentInfo[dependentOn];
            dependence = item["invertDependence"] ? !dependence : dependence;
          }
          return (
            <div
              key={item.type}
              className={
                "impact-container-resolver  impact-subContainer-resolver" +
                item.type
              }
            >
              {item.hasOwnProperty("flexComponent") && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: item.flexComponent.flex,
                  }}
                  className={"impact-flexComponent-" + item.type}
                >
                  {item.flexComponent.components.map((thisComp, index) => {
                    return (
                      <>
                        <div>
                          {renderView({
                            key: index,
                            type: thisComp.type,
                            data: thisComp,
                          })}
                        </div>
                        <div style={{ marginRight: "1rem" }} />
                      </>
                    );
                  })}
                </div>
              )}
              {dependence ? (
                <>
                  {item["rootDependentName"] ? (
                    <>
                      {dependentInfo[`${item.rootDependentName}`] ? (
                        <>
                          {renderView({
                            key: index,
                            type: item.type,
                            data: item,
                          })}
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {renderView({
                        key: index,
                        type: item.type,
                        data: item,
                      })}
                    </>
                  )}
                </>
              ) : null}

              {/* <div className="content-spacer"></div> */}
            </div>
          );
        })}
    </section>
  );
};

export default ComponentResolver;

import { useState, useEffect, useRef } from "react";
import { getMultipleModelsData } from "../action";
import OrangeChart from "../../../assets/svg/orangeChart.svg";
import RedChart from "../../../assets/svg/redChart.svg";
import DarkBlueChart from "../../../assets/svg/blueChart.svg";
import LightBlueChart from "../../../assets/svg/lightBlueChart.svg";
import GreenChart from "../../../assets/svg/greenChart.svg";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { IconButton } from "@mui/material";

const ExpandableKpiCard = ({ data }) => {
  const [cardData, setCardData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const cardWrapperRef = useRef(null);
  useEffect(() => {
    if (data?.info?.key) {
      getMultipleModelsData({
        model_names: [`${data?.info?.key}`],
      }).then((res) => {
        const responseData = res?.[data?.info?.key];
        setCardData(responseData);
      });
    }
  }, []);

  const wrapperStyles = {
    gap: "2rem",
    display: "flex",
    padding: "0 2rem",
    overflowX: "hidden",
    alignItems: "center",
    position: "relative",
    width: isExpanded ? "95%" : "100%",
    margin: "16px",
    flexWrap: isExpanded ? "nowrap" : "wrap",
    marginLeft: isExpanded ? "30px" : "16px",
    ...data?.info?.wrapperStyles,
  };
  const expanderIconStyle = {
    width: "2.25rem",
    border: "0.8px solid #758490",
    height: "2.3125rem",
    display: "flex",
    padding: 0,
    alignItems: "center",
    borderRadius: "0.25rem",
    justifyContent: "center",
    ...data?.info?.expanderIconStyle,
  };
  const cardStyle = {
    minWidth: "15.8125rem",
    border: "1px solid #e5e5e5",
    display: "flex",
    padding: "1rem",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.12)",
    maxHeight: "6.5625rem",
    alignItems: "center",
    borderRadius: "0.625rem",
    ...data?.info?.cardStyle,
  };
  const buttonWrapperStyle = {
    width: "98%",
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 5,
    ...data?.info?.buttonWrapperStyle,
  };
  const buttonStyle = {
    border: "0.8px solid #39496050",
    width: "30px",
    border: "0.8px solid #758490",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    ...data?.info?.buttonStyle,
  };
  const iconStyle = {
    marginRight: "1.25rem",
  };
  const headingStyle = {
    color: "#394960",
    fontSize: "14px",
    wordWrap: "break-word",
    fontWeight: 400,
    textOverflow: "ellipsis",
    letterSpacing: "0.16px",
    lineClamp: 1,
    margin: 0,
    lineHeight: 1.4375,
    ...data?.info?.headingStyle,
  };
  const valueStyle = {
    color: "#1D1D1D",
    overflow: "hidden",
    fontSize: "1.5rem",
    fontWeight: 500,
    textOverflow: "ellipsis",
    margin: 0,
    lineHeight: 1.235,
    ...data?.info?.valueStyle,
  };
  const helperTextStyle = {
    color: "#758490",
    fontSize: "14px",
    fontWeight: 400,
    letterSpacing: "0.14px",
    margin: 0,
    lineHeight: 1.235,
    ...data?.info?.helperTextStyle,
  };

  const iconImageStyle = {
    width: "14px",
    color: "#394960",
    cursor: "pointer",
  };
  const iconRenderer = (color) => {
    switch (color) {
      case "green":
        return <img src={GreenChart} style={iconStyle} />;
      case "orange":
        return <img src={OrangeChart} style={iconStyle} />;
      case "darkBlue":
        return <img src={DarkBlueChart} style={iconStyle} />;
      case "lightBlue":
        return <img src={LightBlueChart} style={iconStyle} />;
      case "red":
        return <img src={RedChart} style={iconStyle} />;
      default:
        return <img src={RedChart} style={iconStyle} />;
    }
  };

  const handleScrollLeft = () => {
    if (cardWrapperRef?.current) {
      cardWrapperRef.current.scrollLeft += 288;
    }
  };
  const handleScrollRight = () => {
    if (cardWrapperRef?.current) {
      cardWrapperRef.current.scrollLeft -= 288;
    }
  };

  return (
    <>
      {cardData?.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: 500 }}>{data?.info?.title || " "}</p>
            <IconButton
              onClick={() => setIsExpanded(!isExpanded)}
              sx={expanderIconStyle}
            >
              {isExpanded ? (
                <OpenInFullIcon sx={iconImageStyle} />
              ) : (
                <CloseFullscreenIcon sx={iconImageStyle} />
              )}
            </IconButton>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isExpanded && (
              <div style={buttonWrapperStyle}>
                <IconButton sx={buttonStyle} onClick={handleScrollRight}>
                  <ArrowBackIcon sx={iconImageStyle} />
                </IconButton>
                <IconButton sx={buttonStyle} onClick={handleScrollLeft}>
                  <ArrowForwardIcon sx={iconImageStyle} />
                </IconButton>
              </div>
            )}
            <div style={wrapperStyles} ref={cardWrapperRef}>
              {cardData?.map((element) => (
                <div style={cardStyle}>
                  <div>{iconRenderer(element?.iconColor)}</div>
                  <div>
                    <p style={headingStyle}>{element?.heading}</p>
                    <p style={valueStyle}>{element?.value}</p>
                    <p style={helperTextStyle}>{element?.helperText}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ExpandableKpiCard;

import { useCallback, useEffect,useState } from "react"
import { formatNumber } from "./utils"

export default function CustomAggregate(params) {
    const aggrValue = params.node.aggData[params.colDef.field]
    const [editMode, setEditMode] = useState(false)
    const [value, setValue] = useState(aggrValue)
    
    useEffect(() => {
        setValue(aggrValue)
    },[aggrValue])
    
    const distribute = useCallback((currentAggValue, newAggValue) => {
        const diff = newAggValue - Number(currentAggValue.replaceAll(",",""))
        if (diff) {
            const field = params.colDef.field
            const children = params.node.allLeafChildren
            let newAggData = 0;
            switch (params.distribution) {
                case 'percentage': {
                    children.forEach(node => {
                        let currentCellValue = Number(node.data[field])
                        currentCellValue = isFinite(currentCellValue) ? currentCellValue : 0;
                        const perc = Number(node.data[params.sourceColumn]) / 100
                        const newCellValue = newAggValue * perc
                        node.data[field] = Math.round(newCellValue).toString()
                    });
                    newAggData = newAggValue;
                    break
                }
                default: {
                    const delta = (diff / children.length)
                    children.forEach(node => {
                        let prevValue = Number(node.data[field])
                        prevValue = isFinite(prevValue) ? prevValue : 0;
                        const v = Math.max(prevValue + delta, 0)
                        node.data[field] = Math.round(v).toString();
                        newAggData += v;
                    })
                    break
                }
            }
            params.node.aggData[field] = formatNumber(newAggData);
            setTimeout(() => {
                params?.api?.refreshCells({
                    force: true,
                    suppressFlash: true,
                });
            }, 0);
        }
        
    }, [params])
    
    const onEnter = (e) => {
        if (e.key === "Enter") {
            const newValue = Number((e.target.value).replaceAll(",", ""))
            if (isFinite(newValue)) {
                distribute(value, newValue)
            }
            setEditMode(false)
        }
    }
    return !editMode ?
        <div onDoubleClick={() => setEditMode(true)} style={{ width: '100%' }}>{value}</div>
        : <input className="custom-aggregate-textbox"
            defaultValue={value}
            onKeyDown={onEnter}
            onBlur={() => setEditMode(false)} autoFocus/>
}
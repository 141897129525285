const initialState = {
  initial: {},
  apiData: {},
  enabledTable: "",
  selectedRowInfo: {},
  respectiveRowDataOnRowCellClick: {},
  respectiveRowDataOnAddNewRowIconClick: {},
  addRowInTableAtIndex: 0,
  targetRowInTableToDelete: null,
  tableInfo: {},
  editActionInfo: {},
  dependentInfo: { evenSelectionChange: false, oddSelectionChange: true },
  tabRedirect: {},
  formEditedValues: {},
  columnsInfo: {},
  isExportTable: false,
  deleteTableRow: false,
  addGridRows: false,
  showActiveTab: false,
  tabIndexValue: 0,
  redirectedTabIndexValue: null,
  tableValues: {},
  selectedDropdownValues: {},
  selectedCheckbox: [],
  gridRefs: {},
  tableInfoSaved: {},
  modalRefs: {},
  dropdownInfo: {},
  dropdownSelectionData: {},
  filtersDependentInfo: {},
  checkedRows: [],
  dependentCheckbox: {
    box1: true,
    box2: false,
  },
  copiedTaleCellValue: "",
  pageConfigData: {},
  dropdownMapping: {},
  buffer: {},
  tableInfoCopy: {},
  tableDefs: {},
  clientConfig: [],
  paramsRefs:{},
tableModals:{}
};

const homeReducer = (state = initialState, action) => {
  console.log("Home Reducer", action);
  switch (action.type) {
    case "INITIAL_DATA":
      return {
        ...state,
        initial: action.payload,
      };
    case "API_DATA":
      return {
        ...state,
        apiData: action.payload,
      };
    case "DISPLAY_TABLE":
      return {
        ...state,
        enabledTable: action.payload,
      };
    case "TABLE_DATA":
      return {
        ...state,
        tableInfo: { ...state.tableInfo, ...action.payload },
      };
    case "TABLE_DATA_COPY":
      return {
        ...state,
        tableInfoCopy: { ...state.tableInfo, ...action.payload },
      };
    case "COPY_TABLE_CELL_DATA":
      return {
        ...state,
        copiedTaleCellValue: action.payload,
      };
    case "EXPORT_TO_EXCEL":
      return {
        ...state,
        isExportTable: action.payload,
      };
    case "DELETE_SELECTED_TABLE_ROW":
      return {
        ...state,
        deleteTableRow: action.payload,
      };
    case "ADD_TABLE_ROW":
      return {
        ...state,
        addGridRows: action.payload,
      };
    case "SET_ROW_DATA_ON_CELL_CLICK":
      return {
        ...state,
        respectiveRowDataOnRowCellClick: { ...action.payload },
      };
    case "SET_ROW_DATA_ON_ADD_ICON_CLICK":
      return {
        ...state,
        respectiveRowDataOnAddNewRowIconClick: { ...action.payload },
        addRowInTableAtIndex: action.targetRowIndex,
      };
    case "DELETE_ROW_FROM_TABLE_ON_DELETE_ICON_CLICK":
      return {
        ...state,
        targetRowInTableToDelete: action.targetRowIndex,
      };
    case "SELECTED_ROWS":
      return {
        ...state,
        selectedRowInfo: { ...state.selectedRowInfo, ...action.payload },
      };
    case "EDIT_ACTION":
      return {
        ...state,
        editActionInfo: { ...state.editActionInfo, ...action.payload },
      };
    case "UPDATED_SELECTED_ROWS":
      return {
        ...state,
      };
    case "DEPENDENT_COMPONENTS":
      return {
        ...state,
        dependentInfo: { ...state.dependentInfo, ...action.payload },
      };
    case "CHECKED_ROWS":
      return {
        ...state,
        checkedRows: { ...state.checkedRows, ...action.payload },
      };
    case "DEPENDENT_CHECKBOX":
      return {
        ...state,
        dependentCheckbox: { ...state.dependentCheckbox, ...action.payload },
      };
    case "RESET_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "TAB_REDIRECT":
      return {
        ...state,
        tabRedirect: { ...state.tabRedirect, ...action.payload },
      };
    case "FORM_EDITED_VALUES":
      return {
        ...state,
        formEditedValues: { ...state.formEditedValues, ...action.payload },
      };
    case "COLUMN_DATA":
      return {
        ...state,
        columnsInfo: { ...state.columnsInfo, ...action.payload },
      };
    case "RESET_TAB_REDIRECT":
      return {
        ...state,
        tabRedirect: {},
      };
    case "SHOW_ACTIVE_TAB":
      return {
        ...state,
        showActiveTab: action.payload?.showActiveTab === false ? false : true,
        tabIndexValue: action.payload?.tabIndexValue || 0,
      };
    case "REDIRECT_TO_NEW_TAB":
      return {
        ...state,
        redirectedTabIndexValue: action.payload.tabIndexValue,
      };

    case "TABLE_VALUES":
      return {
        ...state,
        tableValues: { ...state.tableValues, ...action.payload },
      };
    case "GET_DROPDOWN_SELECTION_DATA":
      return {
        ...state,
        dropdownSelectionData: {
          ...state.dropdownSelectionData,
          ...action.payload,
        },
      };
    case "ADD_DYNAMIC_SCENARIO":
      return {
        ...state,
        dynamicTabContent: action.payload,
      };
    case "NEWLY_ADDED_TAB_INDEX":
      return {
        ...state,
        newDynamicTabIndexValue: action.payload,
      };
    case "SELECTED_DROPDOWN_VALUES":
      return {
        ...state,
        selectedDropdownValues: {
          ...state.selectedDropdownValues,
          ...action.payload,
        },
      };
    case "UPDATE_SELECTED_CHECKBOX":
      return {
        ...state,
        selectedFilter: [action.payload],
      };
    case "SET_GRID_REF":
      return {
        ...state,
        gridRefs: {
          ...state.gridRefs,
          ...action.payload,
        },
      };
    case "TABLE_DATA_SAVED":
      return {
        ...state,
        tableInfoSaved: { ...state.tableInfoSaved, ...action.payload },
      };
    case "MODAL_REFS":
      return {
        ...state,
        modalRefs: { ...state.modalRefs, ...action.payload },
      };
    case "DROPDOWN_INFO":
      return {
        ...state,
        dropdownInfo: {
          ...state.dropdownInfo,
          ...action.payload,
        },
      };
    case "FILTERS_DEPENDENTS":
      return {
        ...state,
        filtersDependentInfo: {
          ...state.filtersDependentInfo,
          ...action.payload,
        },
      };
    case "DELETE_DEPENDENT_COMPONENTS":
      return {
        ...state,
        dependentInfo: { ...action.payload },
      };
    case "SET_PAGE_CONFIG_DATA":
      return {
        ...state,
        pageConfigData: { ...action.payload },
      };

    case "DROPDOWN_MAP":
      return {
        ...state,
        dropdownMapping: { ...state.dropdownMapping, ...action.payload },
      };
    case "ADD_TO_BUFFER":
      return {
        ...state,
        buffer: { ...state.buffer, ...action.payload },
      };
    case "ADD_TABLE_DEFS":
      return {
        ...state,
        tableDefs: { ...state.tableDefs, ...action.payload },
      };
    case "SET_CLIENT_CONFIG":
      return {
        ...state,
        clientConfig: action.payload || [],
      };
    case "SET_PARAMS":
      return {
        ...state,
        paramsRefs: {
          ...state.paramsRefs, ...action.payload
        }
      }
    case "SET_TABLE_MODAL":
      return {
        ...state,
        tableModals: {
          ...state.tableModals, ...action.payload
        }
      }
    default:
      return state;
  }
};

export default homeReducer;

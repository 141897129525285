import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { useSelector } from "react-redux";
function CellCheck(params) {
  const { gridRefs } = useSelector((state) => state.home);

  useEffect(() => {
    let val = params.value;
    if (params.parentGrid && params.data) {
      const parentGridApi = gridRefs[params.parentGrid].current.api;
      parentGridApi.forEachNode((node) => {
        if (node.data && node.data["id"] === params.data[`#parentNode`]) {
          val = node.data[params.colDef.field];
        }
      });
      params.node.setDataValue(params.colDef.field, val);
    }
  }, []);
  const [checked, value] = parseCellValue(params.value || "");
  function handleChange(event) {
    let bool = event.target.checked;
    if (params.counterColumns && bool) {
      const counterColumnSelected = params.counterColumns.some((field) => {
        const [flag] = parseCellValue(params.data[field]);
        return flag;
      });
      if (counterColumnSelected) {
        bool = false;
      } else {
        const parent = params.node.parent;
        if (parent) {
          parent.allLeafChildren.forEach((node) => {
            const { field } = params.colDef;
            const cellValue = node.data[field];
            if (cellValue && node.data[`#disableRenderer_${field}`] !== "Y") {
              const [_, v] = parseCellValue(cellValue);
              node.setDataValue(field, generateValue(false, v));
            }
          });
        }
      }
    }
    params.node.setDataValue(params.colDef.field, generateValue(bool, value));
  }
  return params.value ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox checked={checked} onChange={handleChange} />
      <p>{value || ""}</p>
    </div>
  ) : (
    <></>
  );
}
function parseCellValue(cellValue) {
  const [flag, value] = cellValue.split(",", 2);
  return [Boolean(+flag), value];
}
function generateValue(flag, value) {
  return `${+flag},${value || ""}`;
}
export default function Renderer(params) {
  const disable = params?.data?.[`#disableRenderer_${params.colDef.field}`];
  return disable === "Y" ? <>{params.value}</> : <CellCheck {...params} />;
}

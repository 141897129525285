import { useEffect,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMultipleModelsData } from "../../action";
import { Card, Grid, Typography ,Link} from "@mui/material";
import useAction from "../../../../utils/useAction";
export default function CardsA(props) {
    const { key,filterId ,action,size} = props.data
  const {
    tableInfo,
    dropdownSelectionData,
    } = useSelector((state) => state.home);
    const dispatch = useDispatch()
    const invokeAction = useAction()
    const rowData = tableInfo[key] || []
    const filterData = getFilterData(dropdownSelectionData[filterId])
    const filteredRows = useMemo(() => {
        if (!filterData.length)
            return rowData
        return rowData.filter(
            (row) => {
                return filterData.includes(row["#filter_id"])
        }
        )
    }, [rowData,filterData])
    useEffect(async () => {
        const res = await getMultipleModelsData({ model_names: [key] })
        dispatch({
            type: "TABLE_DATA",
            payload: { [key]: res[key] }
        });
    },[key])
    return <Grid container spacing={2}>
        {filteredRows.map(row => <Grid item {...(size || {md:3})}>
            <KpiCard data={row} action={(payload)=>invokeAction(action,payload)} hideLink={props?.data?.hideLink || false} style={props?.data?.cardStyle} messageStyle={props?.data?.messageStyle}/>
        </Grid>)}
    </Grid>
}
function getFilterData(data) {
    return data && data.map(obj => obj.value) || []
}
function KpiCard({ data,action ,hideLink,style, messageStyle}) {
    const { value, header, label} = data
    return <Card sx={{padding:2, display:"flex", gap:"15px", border:"1px solid #ededed", height:"110px", ...style }}>
        <Typography variant="h3">
           {value} 
        </Typography>
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center",width:"80%", ...messageStyle}}>
            <Typography variant="h6" noWrap>{header}</Typography>
            {!hideLink && <Link onClick={()=>action(data["#path"])} style={{cursor:"pointer"}}  underline="none">{label}</Link>}
        </div>
    </Card>
}
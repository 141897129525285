import "./App.css";
import React, { Suspense, createContext } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import Cookies from "js-cookie";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Link from "@mui/material/Link";
import jwt_decode from "jwt-decode";
import { theme } from "./theme";
import { AppRoutingModule } from "./routes/appRoutingModule";
import "react-dates/initialize"; 
export const UserContext = createContext();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  // Auth token validation //
  let authToken = Cookies.get("authToken");
  let verify = null;
  if (authToken) {
    verify = jwt_decode(authToken);
  }

  return (
    <div className="page-wrapper">
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <div
            style={{ minHeight: "100vh", padding: "0 24px" }} //background: theme.palette.bgPrimary
          >
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<div>...Loading</div>}>
                {verify?.email_verified ? (
                  <AppRoutingModule />
                ) : (
                  <div className="auth-error">
                    <h4>You're not authorized to access this page</h4>
                  </div>
                )}
              </Suspense>
            </QueryClientProvider>
          </div>
        </StyledEngineProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
